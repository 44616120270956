<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Rechtliches</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <!--<ion-header collapse="condense">
    <ion-toolbar color="paystory-dark">
      <ion-title size="large">Rechtliches</ion-title>
    </ion-toolbar>
  </ion-header>-->

    <div class="py-5 mt-3">
        <ion-list
            class="animate__animated animate__fadeInUp bg-card rounded overflow-hidden">
            <ion-item
                button
                (click)="helper.openLink('https://paystory.de/imprint/')">
                <h2>Impressum</h2>
            </ion-item>

            <ion-item
                button
                (click)="helper.openLink('https://paystory.de/privacy/')">
                <h2>Datenschutz</h2>
            </ion-item>
            <ion-item
                button
                (click)="helper.openLink('https://paystory.de/terms-of-service/')"
                lines="none">
                <h2>AGBs</h2>
            </ion-item>
        </ion-list>
    </div>
</ion-content>

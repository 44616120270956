import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderPlacedPageRoutingModule } from './order-placed-routing.module';

import { OrderPlacedPage } from './order-placed.page';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    imports: [
        LottieModule,
        CommonModule,
        FormsModule,
        IonicModule,
        OrderPlacedPageRoutingModule,
        SwiperModule,
    ],
    declarations: [OrderPlacedPage],
})
export class OrderPlacedPageModule {}

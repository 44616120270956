import { Component, OnDestroy } from '@angular/core';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Capacitor } from '@capacitor/core';
import { Position } from '@capacitor/geolocation';
import { ConnectionStatus, Network } from '@capacitor/network';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Toast } from '@capacitor/toast';
import {
    LoadingController,
    MenuController,
    NavController,
    ViewWillEnter
} from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address, Page, Place } from '@paystory/models';
import { ApiService } from '@paystory/services/api.service';
import { HelperService } from '@paystory/services/helper.service';
import { PlaceService } from '@paystory/services/place.service';
import Swal from 'sweetalert2';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnDestroy, ViewWillEnter {
    networkStatus: ConnectionStatus;

    networkListener = Network.addListener(
        'networkStatusChange',
        async status => {
            console.log('networkStatusChange', status);
            this.networkStatus = status;
            if (this.doPlaceReload) {
                await this.loadPlaces();
            }
        }
    );

    currentPosition: Position;
    formattedAddress: Address;
    currentUser;
    doPlaceReload = true;
    loading: boolean;
    tab: 'restaurant' | 'hairdressers' = 'restaurant';
    search = false;
    listSearch;
    loadedAll = true;
    value = 15;
    allowedType = 'order';

    constructor(
        public placeService: PlaceService,
        public apiService: ApiService,
        private helper: HelperService,
        private barcodeScanner: BarcodeScanner,
        private loadingCtrl: LoadingController,
        private navCtrl: NavController,
        private menuController: MenuController
    ) {}

    async ionViewWillEnter() {
        this.menuController.enable(true);
        if (Capacitor.isNativePlatform()) {
            StatusBar.setStyle({
                style: Style.Dark,
            });
        }

        this.allowedType = await this.placeService.getSelectedOrderType();
        await this.loadPlaces();

        this.apiService.currentUser
            .pipe(untilDestroyed(this))
            .subscribe(async user => {
                this.formattedAddress = user.address;
            });
    }

    ngOnDestroy() {
        Network?.removeAllListeners();
    }

    change($event) {
        console.log($event);
        this.tab = $event;
    }

    async doInfinite(event) {
        if (this.loadedAll) {
            event?.target?.complete();
            return;
        }
        try {
            await this.loadPlaces(this.placeService.places?.currentPage + 1);
            event?.target?.complete();
        } catch (err) {}
    }

    async toggleSearch() {
        this.search = !this.search;

        if (!this.search) {
            this.listSearch = '';
            await this.loadPlaces();
        }
    }

    async loadPlaces(page: number = 1) {
        try {
            this.loading = true;
            const data = await this.placeService.loadPlaces(
                page,
                this.listSearch,
                this.currentPosition,
                this.allowedType
            );
            this.loadedAll =
                this.placeService.places?.currentPage >=
                this.placeService.places?.pageCount;

            if (page > 1 && !data.items.length) {
                Toast.show({ text: 'Keine Einträge mehr vorhanden' });
            }
            this.doPlaceReload = false;
        } catch (e) {
            console.warn('Loading places issue', e);
        } finally {
            setTimeout(() => {
                this.loading = false;
            }, 50);
        }
    }

    async scanQrCode() {
        try {
            const barcodeData = await this.barcodeScanner.scan({
                prompt: '',
                disableAnimations: false,
                disableSuccessBeep: true,
            });
            if (!barcodeData.cancelled) {
                try {
                    // Parsing luca code
                    const lucaMatch = barcodeData?.text?.match(
                        /luca-app\.de\/[^/]+\/([^#]+)#(.+)$/
                    );
                    if (lucaMatch?.length === 3) {
                        const lucaPlaceId = lucaMatch[1];
                        const lucaData = lucaMatch[2];
                        let lucaDataJSON = null;
                        for (let x = 1; x <= lucaData.length; x++) {
                            try {
                                lucaDataJSON = JSON.parse(
                                    atob(lucaData.substr(0, x))
                                );
                                if (lucaDataJSON) {
                                    break;
                                }
                            } catch (_) {
                                console.warn(_);
                            }
                        }
                        if (lucaPlaceId) {
                            let lucaPlaces: Page<Place>;
                            const load = await this.loadingCtrl.create({
                                translucent: true,
                            });
                            await load.present();
                            try {
                                lucaPlaces = await this.placeService.list({
                                    luca: lucaPlaceId,
                                    limit: 1,
                                });
                            } catch (_) {
                            } finally {
                                await load.dismiss();
                            }
                            if (lucaPlaces?.items?.length) {
                                return await this.navCtrl.navigateForward(
                                    'place/' + lucaPlaces.items[0].id,
                                    {
                                        queryParams: lucaDataJSON?.table
                                            ? {
                                                  tableNumber:
                                                      lucaDataJSON?.table,
                                              }
                                            : {},
                                    }
                                );
                            } else {
                                await Swal.fire({
                                    title: 'Information',
                                    text:
                                        'Dies ist ein luca QR Code - aber die Location ist leider noch nicht auf PayStory verfügbar!' +
                                        '\n' +
                                        'Sprich den/die Betreiber*in gerne an und informiere uns per Kontaktformular. Vielen Dank.',
                                    icon: 'info',
                                    showCancelButton: false,
                                    showConfirmButton: true,
                                    showDenyButton: false,
                                    showCloseButton: false,
                                    backdrop: true,
                                    timer: 8000,
                                    timerProgressBar: true,
                                });
                            }
                        }
                    }
                } catch (e) {}
                try {
                    const jsonData = JSON.parse(barcodeData.text);
                    if (jsonData.place) {
                        return await this.navCtrl.navigateForward(
                            'place/' +
                                (jsonData.place?.id ||
                                    jsonData.place?.slug ||
                                    jsonData.place),
                            {
                                queryParams: jsonData,
                            }
                        );
                    }
                } catch (e) {}
                this.helper.appUrlOpen(barcodeData.text);
            }
        } catch (err) {
            console.log('Error', err);
        }
    }

    async doRefresh(event?) {
        try {
            await this.loadPlaces();
        } catch (e) {
        } finally {
            setTimeout(() => {
                event?.target.complete();
            }, 500);
        }
    }

    trackById = (index: number, item: any) => item?.id;

    async deliveryChanged(ev: any) {
        this.allowedType = ev.target.value;
        await this.placeService.setSelectedOrderType(this.allowedType as any);
        await this.doRefresh();
    }
}

import { registerLocaleData } from '@angular/common';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {
    APP_INITIALIZER,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VersionModule } from '@paystory/components/version/version.module';
import { DeviceService } from '@paystory/services/device.service';
import { AuthInterceptor } from '@paystory/services/interceptors/auth.interceptor';
import { createErrorHandler } from '@sentry/angular';
import { CacheModule } from 'ionic-cache';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function playerFactory() {
    return player;
}
registerLocaleData(localeDe, 'de', localeDeExtra);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

/*
Stripe?.setPublishableKey({
  key: 'pk_test_51HqilWBUS7BwAG7fs1RZGPdKVzeVC9Uuyeu15nqaxTnqqM4cKyMNaShVwSygANkfNVKIwtJxVIkYRwJ8KjTu4iov00GndoHgJB',
});*/

@NgModule({
    declarations: [AppComponent],
    imports: [
        LottieModule.forRoot({ player: playerFactory }),
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        IonicModule.forRoot({
            backButtonText: '',
            scrollAssist: true,
        }),
        AppRoutingModule,
        CacheModule.forRoot(),
        IonicStorageModule.forRoot(),
        VersionModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler(),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => async () => {
                await DeviceService.initialized;
            },
            deps: [DeviceService],
            multi: true,
        },
        BarcodeScanner,
        CallNumber,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Zahlungsmethoden</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="px-5">
        <div
            class="py-3 px-5 my-4 dark:bg-dark-800 dark:text-light bg-gray-100 text-gray-900 rounded-md text-sm border border-gray-200"
            role="alert">
            Zahlungsmethoden können während dem Bestellvorgang hinzugefügt
            werden.
        </div>
        <ion-item
            *ngFor="let paymentMethod of paymentMethods"
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none">
            <img
                slot="start"
                src="/assets/icons/payment/masterpass.svg" />
            <ion-label>
                <h2>... {{ paymentMethod.card.last4 }}</h2>
                <p>1,40% + 0,25 €</p>
            </ion-label>
            <ion-button
                slot="end"
                color="danger"
                (click)="deletePaymentMethod(paymentMethod.id)"
                >Löschen</ion-button
            >
        </ion-item>

        <ion-item
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none"
            *ngIf="isGooglePayAvailable">
            <img
                slot="start"
                src="/assets/icons/payment/googlepay.svg" />
            <ion-label>
                <h2>Google Pay</h2>
                <p>1,40% + 0,25 €</p>
            </ion-label>
        </ion-item>

        <ion-item
            *ngIf="isApplePayAvailable"
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none">
            <img
                slot="start"
                src="/assets/icons/payment/applepay.svg" />
            <ion-label>
                <h2>Apple Pay</h2>
                <p>1,40% + 0,25 €</p>
            </ion-label>
        </ion-item>

        <ion-item
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none"
            *ngIf="isPayPalAvailable">
            <img
                slot="start"
                src="/assets/icons/payment/paypal.svg" />
            <ion-label
                ><h2>PayPal</h2>
                <p>2,49% + 0,35 €</p></ion-label
            >
        </ion-item>

        <ion-item
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none">
            <img
                slot="start"
                src="/assets/icons/payment/masterpass.svg" />
            <ion-label
                ><h2>Kreditkarte</h2>
                <p>1,40% + 0,25 €</p></ion-label
            >
        </ion-item>

        <ion-item
            class="paymethod item-border rounded overflow-hidden mb-2"
            lines="none"
            *ngIf="false">
            <img
                slot="start"
                src="/assets/icons/payment/sofort.svg" />
            <ion-label
                ><h2>Lastschrift</h2>
                <p>1,35% + 0,20 €</p></ion-label
            >
        </ion-item>
    </div>
</ion-content>

import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderDetailPageRoutingModule } from './order-detail-routing.module';

import { OrderDetailPage } from './order-detail.page';
import { OrderItemComponentModule } from '../../components/order-item/order-item.module';
import { PlaceHeaderModule } from 'src/pages/place/components/place-header/place-header.module';
import { PaymentMethodModule } from '@paystory/components/payment-method/payment-method.module';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        OrderDetailPageRoutingModule,
        OrderItemComponentModule,
        PlaceHeaderModule,
        PaymentMethodModule,
    ],
    declarations: [OrderDetailPage],
})
export class OrderDetailPageModule {}

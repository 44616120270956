<ion-header translucent="true">
    <ion-toolbar color="paystory-dark">
        <ion-title class="title-logo"
            ><img
                class="center"
                src="/assets/logo-text-on-dark.svg"
        /></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <h1 class="p-5 font-bold text-4xl">
        Hey {{ api.currentUserValue?.address?.name }},
    </h1>
    <div class="p-5">
        <p>Du bist fast drin.</p>
        <p class="mt-2">Bitte gib Deine Telefonnummer zur Überprüfung an.</p>
    </div>

    <ion-item lines="none">
        <div class="flex items-center w-full">
            <h3 class="pr-3">+49</h3>
            <ion-input
                class="border-b-2 border-gray-600"
                type="tel"
                [(ngModel)]="phoneNumber"
                placeholder="151 000000"
                (keyup.enter)="!phoneNumber ? '' : loginWithPhone()"></ion-input>
        </div>
    </ion-item>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <h3
            class="flex justify-between"
            *ngIf="false">
            0:20 &nbsp;Sek.
            <span
                class="inline-flex items-center font-bold text-xs px-2 py-2px rounded-full tracking-wide uppercase green-200">
                <span class="pr-2px leading-relaxed whitespace-no-wrap"
                    >Erneut senden</span
                >
            </span>
        </h3>
        <ion-button
            color="primary"
            size="medium"
            expand="full"
            class="m-0"
            (click)="loginWithPhone()"
            [disabled]="!phoneNumber"
            >Fortsetzen</ion-button
        ></ion-toolbar
    >
</ion-footer>

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button
                [defaultHref]="'/saved-addresses'"></ion-back-button>
        </ion-buttons>
        <ion-title>Adresse erstellen</ion-title>
    </ion-toolbar>

    <ion-toolbar color="paystory-dark">
        <ion-searchbar
            [(ngModel)]="autocomplete.input"
            (ionChange)="updateSearchResults()"
            placeholder="Suchen ...">
        </ion-searchbar>
    </ion-toolbar>

    <ion-list
        class="filter-list"
        *ngIf="autocompleteItems.length != 0">
        <ion-item
            *ngFor="let item of autocompleteItems"
            (click)="selectSearchResult(item)">
            {{ item.description }}
        </ion-item>
    </ion-list>
</ion-header>

<ion-content>
    <google-map [options]="locationService.options"></google-map>

    <ion-fab
        vertical="bottom"
        horizontal="start"
        slot="fixed">
        <ion-fab-button (click)="saveLocation()">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>

    <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed">
        <ion-fab-button (click)="tryGeolocation()">
            <ion-spinner *ngIf="loading"></ion-spinner>
            <ion-icon
                *ngIf="!loading"
                name="location-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>

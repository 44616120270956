/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ProductOption } from '@paystory/models/product-option.interface';
import { HttpClient } from '@angular/common/http';
import {
    Address,
    Order,
    OrderItem,
    OrderItemOptionGroup,
    Place,
    Product,
    ProductOptionGroup,
} from '@paystory/models';
import { DeviceService } from './device.service';
import { get, set, remove } from './storage.service';
import { PlaceService } from '@paystory/services/place.service';
import { ApiService } from '@paystory/services/api.service';

interface SubmitOrderItem {
    productOptionGroup: string;
    orderItemOptions: {
        productOption: string;
        orderItemOptionGroups: SubmitOrderItem[];
    }[];
}

interface StoredCart {
    type?: 'order' | 'delivery' | 'pickup';
    details?: { [key: string]: string };
    items?: any[];
    paymentMethod?: string;
    tip?: number;
    notes?: string;
    updatedAt?: string;
    deliveryAddress?: Address;
}

@Injectable({
    providedIn: 'root',
})
export class CartService {
    place?: Place;
    placeId?: string;
    public products: Observable<OrderItem[]>;
    private productsSubject: BehaviorSubject<OrderItem[]>;
    data: StoredCart = {};

    constructor(
        private http: HttpClient,
        private placeService: PlaceService,
        private api: ApiService
    ) {
        this.productsSubject = new BehaviorSubject<OrderItem[]>([]);
        this.products = this.productsSubject.asObservable();
        this.productsSubject.subscribe(() => {
            const placeId = this.place?.id || this.placeId;
            if (placeId) {
                set(
                    `place-cart-${placeId}`,
                    Object.assign({}, this.data, {
                        updatedAt: new Date().toISOString().split('T')[0],
                    })
                );
            }
        });
    }

    async openPlace(place: Place, details: any = {}) {
        this.place = place;
        this.placeId = place.id;

        this.data = (await get(`place-cart-${place.id}`)) || {};
        this.data.type = await this.placeService.getSelectedOrderType();
        if (this.data.type === 'delivery' && !this.data.deliveryAddress) {
            this.data.deliveryAddress =
                this.api.currentUserValue?.address || {};
        }
        if (this.data?.updatedAt !== new Date().toISOString().split('T')[0]) {
            this.data = { items: [], paymentMethod: 'stripe', details };
        }
        this.productsSubject.next(this.data.items);
        if (!this.place?.type) {
            Object.assign(
                place,
                await this.placeService.get(this.place?.id || this.placeId)
            );
        }
    }

    async productToOrderItem(product: Product): Promise<OrderItem> {
        const addOrderItemOptionGroups = (p: ProductOptionGroup) => ({
            productOptionGroup: p,
            orderItemOptions: p.productOptions
                .filter(po => po.selected)
                .map(po => ({
                    productOption: po,
                    title: po.title,
                    price: po.price,
                    vatPercent: po.vatPercent,
                    orderItemOptionGroups: po.productOptionGroups?.map(pog =>
                        addOrderItemOptionGroups(pog)
                    ),
                })),
        });
        return {
            product,
            name: product.name,
            unitPrice: product.price,
            vatPercent: product.vatPercent,
            orderItemOptionGroups: product.productOptionGroups
                ?.map(pog => addOrderItemOptionGroups(pog))
                .filter(pog => pog.orderItemOptions?.length > 0),
        };
    }

    async addProduct(product: Product, quantity?: number) {
        const products = this.productsSubject.value || [];
        const orderItem = await this.productToOrderItem(product);
        orderItem.quantity = quantity || 1;
        this.calculateProduct(orderItem);
        products.push(orderItem);
        this.productsSubject.next(products);
    }

    calculateProduct(orderItem: OrderItem): void {
        let unitPrice = orderItem.unitPrice;
        const addGroups = (orderItemOptionGroups: OrderItemOptionGroup[]) => {
            for (const itemOptionGroup of orderItemOptionGroups) {
                for (const itemOption of itemOptionGroup.orderItemOptions) {
                    addGroups(itemOption.orderItemOptionGroups);
                    unitPrice += itemOption.price;
                }
            }
        };
        addGroups(orderItem.orderItemOptionGroups);
        orderItem.total = unitPrice * orderItem.quantity;
    }

    async changeProductAmount(product: OrderItem, amount?: number) {
        if (amount === null) {
            return;
        }
        product.quantity = amount;
        this.calculateProduct(product);
        await this.saveData();
    }

    async deleteProduct(product: OrderItem) {
        const products = this.productsSubject.value;
        const i = products.indexOf(product);
        if (i > -1) {
            products.splice(i, 1);
            this.productsSubject.next(products);
        }
    }

    getProductPrice(product: Product | Product[]) {
        let amount = 0;
        const addAmount = (
            productOrOption: OrderItem[] | ProductOption[],
            parentAmount?: number
        ) => {
            productOrOption.forEach(poo => {
                poo?.productOptionGroups?.forEach(pog => {
                    addAmount(
                        pog.productOptions,
                        parentAmount || ((poo as any).amount || 1) - 1
                    );
                });
                amount +=
                    +poo.price *
                    (((poo as any).amount || 1) + (parentAmount || 0));
            });
        };
        addAmount(
            (typeof (product as any).length === 'number'
                ? product
                : [product]) as any,
            0
        );
        return amount;
    }

    getTotal() {
        return this.products.pipe(
            map(products => products.reduce((pv, cv) => pv + cv.total, 0))
        );
    }

    getTipPercent() {
        return this.getTotal().pipe(
            map(amount => (this.data?.tip / amount) * 100)
        );
    }

    getFees(): Observable<number> {
        return this.getTotal().pipe(
            map(amount => {
                if (amount <= 0 || this.data?.paymentMethod === 'cash') {
                    return 0;
                }
                amount += this.data?.tip || 0;
                return +(
                    (this.data?.paymentMethod === 'paypal'
                        ? +((amount + 0.35) / 0.9751)
                        : this.data?.paymentMethod === 'klarna'
                        ? +((amount + 0.2) / 0.9865)
                        : +((amount + 0.25) / 0.986)) - amount
                ).toFixed(2);
            })
        );
    }

    getTotalWithFee() {
        return this.getTotal().pipe(
            switchMap(total =>
                this.getFees().pipe(
                    take(1),
                    map(
                        fees =>
                            total + fees + (total > 0 ? this.data?.tip || 0 : 0)
                    )
                )
            )
        );
    }

    getProducts(): OrderItem[] {
        return this.productsSubject.value;
    }

    async selectPaymentMethod(paymentMethod: string) {
        this.data.paymentMethod = paymentMethod;
        await this.saveData();
    }

    async setTip(tip: number) {
        if (this.data?.tip?.toFixed(2) !== tip.toFixed(2)) {
            this.data.tip = +tip.toFixed(2);
            await this.saveData();
        }
    }

    async setDetail(key: string, value: any) {
        this.data.details[key] = value;
        await this.saveData();
    }

    async setNotes(notes: string) {
        this.data.notes = notes;
        await this.saveData();
    }

    public async saveData() {
        this.productsSubject.next(this.productsSubject.value);
    }

    async checkout(
        orderId: string,
        data: {
            token?: string;
            save?: boolean;
            paymentUi?: boolean;
        }
    ) {
        const response = await lastValueFrom(
            this.http.post<
                Order & {
                    paymentIntent?: string;
                    ephemeralKey?: string;
                    customer?: string;
                    merchantDisplayName?: string;
                    sandbox?: boolean;
                    amount?: number;
                }
            >(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/orders/${orderId}/checkout`,
                data
            )
        );
        if (response?.paymentIntent) {
            return response;
        }
    }

    async clear() {
        this.productsSubject.next([]);
        (this.place || this.placeId) &&
            (await remove(`place-cart-${this.place?.id || this.placeId}`));
        delete this.place;
    }

    async createOrder() {
        const convertOrderItemOptionGroups = (
            orderItemOptionGroups: OrderItemOptionGroup[]
        ) => {
            for (const itemOptionGroup of orderItemOptionGroups) {
                for (const itemOption of itemOptionGroup.orderItemOptions) {
                    convertOrderItemOptionGroups(
                        itemOption.orderItemOptionGroups
                    );
                    (itemOption as any).productOption =
                        itemOption.productOption?.id;
                }
                (itemOptionGroup as any).productOptionGroup =
                    itemOptionGroup.productOptionGroup?.id;
            }
        };

        const orderItems = [];
        for (const originalOrderItem of JSON.parse(
            JSON.stringify(this.productsSubject.value)
        )) {
            const orderItem = Object.assign({}, originalOrderItem, {
                product: originalOrderItem.product?.id,
            });
            convertOrderItemOptionGroups(orderItem.orderItemOptionGroups);
            orderItems.push(orderItem);
        }

        return await lastValueFrom(
            this.http.post<Order>(
                `${DeviceService.getEnvironmentParameter('api')}/api/orders`,
                {
                    type: this.data?.type,
                    place: this.place?.id || this.placeId,
                    orderItems,
                    details: this.data?.details || {},
                    tip: this.data?.tip || 0,
                    paymentMethod: this.data?.paymentMethod || null,
                    notes: this.data?.notes || '',
                    deliveryAddress: this.data?.deliveryAddress || null,
                }
            )
        );
    }

    async checkCoupon(coupon: string) {
        return await this.http.post<any>(
            `${DeviceService.getEnvironmentParameter('api')}/api/coupon`,
            { coupon }
        );
    }
}

import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Place, Page } from '@paystory/models';
import { DeviceService } from './device.service';
import { lastValueFrom } from 'rxjs';
import { MagicCode } from '@paystory/models/magic-code.interface';

@Injectable({
    providedIn: 'root',
})
export class MagicCodeService {
    activePlace: Place;
    placeId: string;

    constructor(
        private http: HttpClient,
        private loadingCtrl: LoadingController
    ) {}

    async loadPlace(id?: string) {
        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        await loading.present();

        try {
            const place = await this.get(id || this.placeId);

            console.log(place);
            this.activePlace = place;
        } catch (error) {
        } finally {
            await loading.dismiss();
        }
    }

    async list(params?) {
        return await this.http
            .get<Page<Place>>(
                `${DeviceService.getEnvironmentParameter('api')}/api/places`,
                params ? { params } : undefined
            )
            .toPromise();
    }

    async get(id: string): Promise<MagicCode> {
        return lastValueFrom(
            this.http.get(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/magic_codes/${id}`
            )
        );
    }
}

<ion-content>
    <div
        class="content-container"
        [style.bottom.px]="keyboardSize">
        <div class="animate__animated animate__fadeInUp px-8">
            <img
                src="/assets/logo-text-on-dark.svg"
                class="w-56 mb-5 logo" />
            <div
                class="header mb-5"
                (click)="deviceService.enableDeveloperMode()">
                <!--<div>Reservieren.</div>-->
                <div>Bestellen.</div>
                <div>Bezahlen.</div>
                <div>Liefern.</div>
            </div>

            <!--<h2>Bestelle Essen direkt zu deinem Tisch</h2>-->
            <div class="info">Jetzt einloggen und loslegen</div>
        </div>

        <div class="animate__animated animate__fadeInUp btn-container px-8">
            <div class="w-full items-center phone-input">
                <ion-item lines="none">
                    <div class="flex items-center w-full">
                        <h3 class="mr-3 whitespace-nowrap">+49</h3>
                        <ion-input
                            type="tel"
                            [(ngModel)]="phoneNumber"
                            placeholder="151 0000000"></ion-input>
                        <ion-button
                            [disabled]="!phoneNumber"
                            size="medium"
                            class="p-btn end"
                            (click)="loginWithPhone()"
                            >Los</ion-button
                        >
                    </div>
                </ion-item>
            </div>
            <div class="w-full text-center mb-4 or">oder</div>
            <ion-button
                class="facebook"
                expand="block"
                (click)="login('facebook')">
                <span class="wrapper"
                    ><ion-icon
                        slot="start"
                        name="logo-facebook"></ion-icon
                    ><span class="text">Facebook</span></span
                >
            </ion-button>
            <!--<ion-button class="google" expand="block" (click)="login('google')">
      <span class="wrapper">
        <img src="assets/icons/512px-Google__G__Logo.svg.webp" />
        <span class="text">Google</span></span
      >
    </ion-button>
   <ion-button class="phone" expand="block" (click)="login('phone')">
        <span class="wrapper"
          ><ion-icon slot="start" name="call"></ion-icon>
          <span class="text">Telefonnummer</span></span
        >
      </ion-button>-->

            <ion-button
                class="apple"
                expand="block"
                (click)="login('apple')"
                *ngIf="isiOS">
                <span class="wrapper"
                    ><ion-icon
                        slot="start"
                        name="logo-apple"></ion-icon
                    ><span class="text">Apple</span></span
                >
            </ion-button>
        </div>
    </div>
</ion-content>

<div class="overlay"></div>
<div class="background">
    <img
        src="assets/images/table.jpeg"
        class="h-full w-full object-cover object-right" />
    <!--<video
        #video
        class="h-full w-full object-cover object-left-top"
        loop
        muted
        autoplay
        (canplay)="video.play()"
        (loadedmetadata)="video.muted = true">
        <source
            src="assets/videos/hd.mp4"
            type="video/mp4" />
    </video>-->
</div>

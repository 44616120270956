<div class="grid grid-flow-col grid-cols-3 gap-4">
    <div
        *ngFor="let item of items"
        class="bg-blue-50 rounded flex flex-col items-center justify-center p-3 tip-item"
        (click)="activate(item)"
        [class.active]="selectedTip === item">
        <div class="font-bold text-xl">{{ item.amount }}%</div>
        <div class="font-bold text-xs">
            {{ total * (item.amount / 100) | currency: 'EUR' }}
        </div>
    </div>
</div>
<div
    [class.active]="tipAmount === 0"
    class="tip-item  bg-blue-50 rounded flex flex-col items-center justify-center py-1 px-3 mt-3"
    (click)="noTip()">
    <div class="font-bold text-xl">0%</div>
</div>

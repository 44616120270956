<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Bestellungen</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-refresher
        slot="fixed"
        (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div class="px-5">
        <div
            class="font-semibold text-left text-xs  uppercase tracking-wider leading-none mb-5 mt-5">
            {{ 'yourOrders' | translate }}
        </div>

        <loading-item
            *ngIf="!(pageInProgress?.items?.length > 0)"
            [loading]="pageInProgressLoading"
            [message]="'Keine Bestellungen'"></loading-item>

        <div *ngIf="!pageInProgressLoading">
            <app-order-item
                class="block mb-5"
                *ngFor="let order of pageInProgress?.items"
                [order]="order"></app-order-item>
        </div>

        <!--<div
      class="
        font-semibold
        text-left text-xs
        uppercase
        tracking-wider
        leading-none
        mb-2
        mt-10
      "
    >
      {{'past_orders' | translate}}
    </div>

    <loading-item
      *ngIf="!(pageFulfilled?.items?.length > 0)"
      [loading]="pageFulfilledLoading"
      [message]="'Keine Bestellungen'"
    ></loading-item>

    <div *ngIf="!pageFulfilledLoading">
      <app-order-item
        class="block mb-5"
        *ngFor="let order of pageFulfilled?.items"
        [order]="order"
      ></app-order-item>
    </div>-->
    </div>
</ion-content>

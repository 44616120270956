import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ViewDidEnter, ViewWillEnter } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ApiService } from '@paystory/services/api.service';
import { DeviceService } from '@paystory/services/device.service';
import { HelperService } from '@paystory/services/helper.service';
import { LocationService } from '@paystory/services/location.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

export interface DeliveryAddress {
    company?: string;
    name?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    phoneNumber?: string;
    deliveryNotes?: string;

    latitude?: number;
    longitude?: number;

    lat?: number;
    lng?: number;

    edit?: boolean;
    default?: boolean;
}
@Component({
    selector: 'app-saved-addresses',
    templateUrl: './saved-addresses.page.html',
    styleUrls: ['./saved-addresses.page.scss'],
})
@UntilDestroy()
export class SavedAddressesPage implements ViewWillEnter {
    savedAddresses: Array<DeliveryAddress>;
    addressBeforeSave: DeliveryAddress;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private locationService: LocationService,
        private loadingCtrl: LoadingController,
        private helperService: HelperService
    ) {}

    async ionViewWillEnter() {
        await this.load();
    }

    async load() {
        const loading = await this.loadingCtrl.create({});
        loading.present();
        try {
            this.savedAddresses = this.apiService.currentUserValue?.address
                ? [
                      {
                          ...this.apiService.currentUserValue?.address,
                          default: true,
                      } as DeliveryAddress,
                  ]
                : [];
            let deliveryAddresses = await lastValueFrom(
                this.http.get<DeliveryAddress[]>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/users/me/delivery_addresses`
                )
            );
            if (deliveryAddresses.length) {
                this.savedAddresses =
                    this.savedAddresses.concat(deliveryAddresses);
            }
        } catch (error) {}
        loading.dismiss();
        return true;
    }

    async remove(address, hideDialog = false) {
        try {
            hideDialog || (await this.helperService.confirmDelete());
            const loading = await this.loadingCtrl.create();
            loading.present();

            try {
                await lastValueFrom(
                    this.http.delete(
                        `${DeviceService.getEnvironmentParameter(
                            'api'
                        )}/api/users/me/delivery_addresses/${address.id}`
                    )
                );
                await this.load();
            } catch (error) {
                // Sentry.captureException(error);
            } finally {
                loading.dismiss();
            }
        } catch (error) {}
    }

    async save(address) {
        const loading = await this.loadingCtrl.create();
        loading.present();
        try {
            const addressString = `${address.address || ''}, ${
                address.zipCode || ''
            } ${address.city || ''}`;
            const data = await this.locationService.getLatLngFromAddress(
                addressString
            );

            address.latitude = data.geometry.location.lat();
            address.longitude = data.geometry.location.lng();

            if (address.default) {
                await this.apiService.updateMyself({ address });
            } else {
                await lastValueFrom(
                    this.http.post(
                        `${DeviceService.getEnvironmentParameter(
                            'api'
                        )}/api/users/me/delivery_addresses/${address.id}`,
                        address
                    )
                );
            }
            await this.load();
        } catch (error) {
            // Sentry.captureException(error);
        } finally {
            address.edit = false;
            loading.dismiss();
        }
    }

    async default(address: DeliveryAddress) {
        const loading = await this.loadingCtrl.create();
        loading.present();
        try {
            await this.apiService.updateMyself({ address });
            await this.remove(address, true);
        } catch (error) {
            // Sentry.captureException(error);
        } finally {
            address.edit = false;
            loading.dismiss();
        }
    }

    trackBy(index, item) {
        return item.id;
    }

    async doRefresh($event) {
        await this.load();
        setTimeout(() => {
            $event.target.complete();
        }, 200);
    }

    async onChange(address: DeliveryAddress) {
        this.addressBeforeSave = { ...address };
        console.log(this.addressBeforeSave);

        address.edit = true;
    }

    onCancel(index: number, address: DeliveryAddress) {
        this.savedAddresses[index] = { ...this.addressBeforeSave };
        console.log(this.savedAddresses[index]);

        address.edit = false;
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { OrderCartProductOptionGroupComponent } from './order-cart-product-option-group.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, DistancePipeModule],
    declarations: [OrderCartProductOptionGroupComponent],
    exports: [OrderCartProductOptionGroupComponent],
})
export class OrderCartProductOptionGroupComponentModule {}

import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-place-imprint',
    templateUrl: './place-imprint.page.html',
    styleUrls: ['./place-imprint.page.scss'],
})
export class PlaceImprintPage implements OnInit {
    @Input() imprint: string;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    close() {
        this.modalCtrl.dismiss();
    }
}

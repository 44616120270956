<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/tabs/home"></ion-back-button>
        </ion-buttons>
        <!--<ion-buttons *ngIf="place?.phoneNumber" slot="end">
      <ion-button (click)="presentPopover($event)">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button> </ion-buttons
  >--></ion-toolbar
    >
</ion-header>

<ion-content
    fullscreen
    class="bg_color">
    <ion-refresher
        slot="fixed"
        (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <app-place-header [place]="place"></app-place-header>
    <ng-container *ngIf="infoTab === 'products'">
        <loading-item
            class="mt-12"
            *ngIf="loading"
            [loading]="true"></loading-item>
        <ng-container *ngIf="!loading">
            <div
                class="tab_content"
                *ngFor="let category of onlyWithProducts(place?.categories); trackBy: trackById"
                [ngSwitch]="tab">
                <div *ngSwitchCase="category.id">
                    <ion-list-header>{{ category.name }}</ion-list-header>
                    <div>
                        <div
                            *ngFor="let product of category.products; trackBy: trackById">
                            <div class="px-5">
                                <ion-item
                                    (click)="variationSelection($event, product)"
                                    lines="none"
                                    class="product bg-card overflow-visible mb-7 rounded-md relative item-border">
                                    <div
                                        [lazyLoad]="product.picture_600x600"
                                        [defaultImage]="'assets/images/image-preloader.svg'"
                                        class="h-16 w-16 absolute -left-2 -top-2 rounded-lg bg-cover bg-no-repeat bg-center shadow-md left-image"></div>

                                    <div class="ml-20 overflow-hidden py-2">
                                        <h2 class="mb-0">{{ product.name }}</h2>
                                        <div
                                            *ngIf="product.information"
                                            class="line-clamp-1 text-xs">
                                            {{ product.information }}
                                        </div>
                                    </div>
                                    <div
                                        slot="end"
                                        class="badge mr-2">
                                        {{ product.price | currency: 'EUR' }}
                                    </div>
                                    <!--<ion-button slot="end" fill="clear" size="small">
                    <ion-icon name="add-circle-outline"></ion-icon>
                  </ion-button>-->
                                </ion-item>
                            </div>
                        </div>
                        <div class="py-8"></div>
                    </div>
                    <div class="px-5">
                        <loading-item
                            *ngIf="!category.products?.length"
                            [message]="'Keine Produkte'"></loading-item>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ion-fab
        *ngIf="place?.hasOpen && (cartService.products | async).length"
        vertical="bottom"
        horizontal="end"
        slot="fixed">
        <div class="badge badge-error">
            {{ cartService.getProducts().length }}
        </div>
        <ion-fab-button (click)="cartService.getProducts().length && cart()">
            <ion-icon name="cart-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>

<div class="variation-selection">
    <app-variation-selection
        *ngIf="cupertino && currentSelectedProduct"
        [product]="currentSelectedProduct"
        (onClose)="myPane?.destroy({ animate: true })"></app-variation-selection>
</div>

<ion-footer
    translucent
    class="mb-0 pb-0">
    <ion-toolbar
        translucent
        class="mb-0 pb-0"
        *ngIf="infoTab === 'products'">
        <ion-segment
            mode="md"
            [(ngModel)]="tab"
            scrollable="true"
            swipeGesture="true">
            <ion-segment-button
                mode="md"
                *ngFor="
          let category of onlyWithProducts(place?.categories);
          trackBy: trackById
        "
                [value]="category.id">
                <ion-label>{{ category.name }}</ion-label>
            </ion-segment-button>
        </ion-segment></ion-toolbar
    >
    <!--<ion-toolbar>
    <div
      class="cart_btn"
      (click)="cartService.getProducts().length && cart()"
      [class.disabled]="!(cartService.products | async).length"
    >
      <h2 class="d-flex">
        <ion-icon name="cart-outline"></ion-icon>
        &nbsp; &nbsp; {{ (cartService.products | async).length }} {{'items' |
        translate}} &nbsp;|&nbsp; {{ (cartTotal$ | async) | currency: 'EUR' }}
      <ion-button
          *ngIf="(cartService.products | async).length"
          size="small"
          class="end"
          >{{'view_cart' | translate}}</ion-button
        >
      </h2>
    </div>
  </ion-toolbar>-->
</ion-footer>

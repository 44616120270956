<div
    class="content pb-12"
    overflow-y>
    <div class="z-10 bg w-full sticky top-0">
        <div
            class="list_header d-flex px-5 pt-8 z-10"
            [class.pb-5]="!product?.information">
            <div class="text_box">
                <h2 class="font-bold">{{ product.name }}</h2>
                <div class="flex items-center">
                    <h3 class="mr-2">{{ product.price | currency: 'EUR' }}</h3>
                    <ion-button
                        fill="clear"
                        size="small"
                        *ngIf="product.allergens?.length > 0"
                        (click)="showAllergens(product)"
                        >Allergene</ion-button
                    >
                </div>
            </div>
            <!--<button
                class="btn btn-xs normal-case end"
                [disabled]="addLoading"
                (click)="add()">
                <ion-spinner
                    class="mr-1"
                    *ngIf="addLoading"
                    slot="start"></ion-spinner
                >{{ 'add' | translate }}
            </button>-->
        </div>
        <div
            *ngIf="product?.information"
            class="text-sm text-gray-600 px-5 py-3">
            {{ product.information }}
        </div>
    </div>
    <app-product-option-group
        *ngIf="product.productOptionGroups?.length > 0"
        [item]="product"></app-product-option-group>
</div>
<!--
<button
class="btn btn-xs normal-case end"
[disabled]="addLoading"
(click)="add()">
<ion-spinner
    class="mr-1"
    *ngIf="addLoading"
    slot="start"></ion-spinner
>{{ 'add' | translate }}
</button
-->
<ion-fab
    vertical="bottom"
    horizontal="end"
    slot="fixed">
    <ion-fab-button
        [disabled]="addLoading"
        (click)="add()">
        <ion-spinner
            class="mr-1"
            *ngIf="addLoading"
            slot="start"></ion-spinner>
        <ion-icon
            *ngIf="!addLoading"
            name="add-outline"></ion-icon>
    </ion-fab-button>
</ion-fab>

import { Injectable } from '@angular/core';
import { Address } from '@paystory/models';
import { googleMapsStyles } from '@paystory/styles';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    public options: google.maps.MapOptions = {
        center: { lat: 51.1642292, lng: 10.4541194 },
        zoom: 6,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        styles: googleMapsStyles,
    };

    formatAddressObject(
        addressComponents: google.maps.GeocoderAddressComponent[]
    ): Address {
        const address: Address = {};
        addressComponents.forEach(component => {
            if (
                component.types.includes('street_number') &&
                !address.streetNumber
            ) {
                address.streetNumber = component.long_name;
            } else if (component.types.includes('route') && !address.address) {
                address.address = component.long_name;
            } else if (component.types.includes('locality') && !address.city) {
                address.city = component.long_name;
            } else if (
                component.types.includes('administrative_area_level_1') &&
                !address.city
            ) {
                address.city = component.long_name;
            } else if (
                component.types.includes('postal_code') &&
                !address.zipCode
            ) {
                address.zipCode = component.long_name;
            } else if (
                component.types.includes('country') &&
                !address.country
            ) {
                address.country = component.long_name;
            }
        });

        return address;
    }

    async getLatLngFromAddress(address: string) {
        const geocoder = new google.maps.Geocoder();
        const { results } = await geocoder.geocode({ address });
        if (results[0]) {
            return results[0];
        }
    }

    async getAddressFromLatLng(lat: number, lng: number): Promise<any> {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(lat, lng);
        const { results } = await geocoder.geocode({ location: latlng });
        if (results[0]) {
            return results[0];
        }
    }
}

<div class="header-image background-size">
    <div
        class="background-image"
        [lazyLoad]="place?.cover_1300x600"
        [defaultImage]="'assets/images/image-preloader.svg'"></div>
    <div
        class="badges"
        *ngIf="place">
        <div class="inline-flex -mt-1 gap-2">
            <div
                [class.badge-success]="place?.hasOpen"
                [class.badge-error]="!place?.hasOpen"
                class="badge">
                <span class="leading-relaxed whitespace-no-wrap">{{
                    place?.hasOpen ? 'Geöffnet' : 'Geschlossen'
                }}</span>
            </div>
            <div
                *ngIf="place?.distance"
                class="badge">
                <span class="pr-2px leading-relaxed whitespace-no-wrap">{{
                    place?.distance | distance
                }}</span>
            </div>
        </div>

        <div
            class="type-badge"
            *ngIf="place?.type === 'restaurant'">
            <ion-icon name="restaurant-outline"></ion-icon>
        </div>
    </div>
    <div class="bar">
        <div>
            <div class="info">
                <div
                    class="place-image"
                    [lazyLoad]="place?.picture_600x600"
                    [defaultImage]="'assets/images/image-preloader.svg'"></div>
                <div class="flex flex-col">
                    <h2 class="font-bold">{{ place?.name }}</h2>
                    <p
                        *ngIf="place?.mainTag?.name"
                        class="text-xs text-white opacity-60 mb-0">
                        {{ place?.mainTag?.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

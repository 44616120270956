import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@paystory/services/guards/auth.guard';
import { NotVerifiedGuard } from '@paystory/services/guards/not-verified.guard';
import { EmailLoginPageModule } from 'src/pages/auth/pages/email-login/email-login.module';
import { LoginPageModule } from 'src/pages/auth/pages/login/login.module';
import { PhoneLoginPageModule } from 'src/pages/auth/pages/phone-login/phone-login.module';
import { RegisterPageModule } from 'src/pages/auth/pages/register/register.module';
import { SocialLoginPageModule } from 'src/pages/auth/pages/social-login/social-login.module';
import { VerificationPageModule } from 'src/pages/auth/pages/verification/verification.module';
import { HomePageModule } from 'src/pages/home/home.module';
import { LegalPageModule } from 'src/pages/legal/legal.module';
import { OrderCartPageModule } from 'src/pages/order/pages/order-cart/order-cart.module';
import { OrderDetailPageModule } from 'src/pages/order/pages/order-detail/order-detail.module';
import { OrderOverviewPageModule } from 'src/pages/order/pages/order-overview/order-overview.module';
import { OrderPaymentPageModule } from 'src/pages/order/pages/order-payment/order-payment.module';
import { OrderPlacedPageModule } from 'src/pages/order/pages/order-placed/order-placed.module';
import { PlaceImprintPageModule } from 'src/pages/place/pages/place-imprint/place-imprint.module';
import { SettingsPageModule } from 'src/pages/settings/settings.module';
import { SupportPageModule } from 'src/pages/support/support.module';
import { TabsPageModule } from 'src/pages/tabs/tabs.module';
import { ProfileEditPageModule } from 'src/pages/user/pages/profile-edit/profile-edit.module';
import { ProfilePaymethodsPageModule } from 'src/pages/user/pages/profile-paymethods/profile-paymethods.module';
import { ProfilePageModule } from 'src/pages/user/pages/profile/profile.module';
import { SetLocationPageModule } from 'src/pages/user/pages/set-location/set-location.module';
import { FavoritesPageModule } from '../pages/favorites/favorites.module';
import { ConfirmAddressPageModule } from '../pages/user/pages/confirm-address/confirm-address.module';
import { SavedAddressesPageModule } from '../pages/user/pages/saved-addresses/saved-addresses.module';
import { PlacePageModule } from './../pages/place/pages/place/place.module';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () => LoginPageModule,
    },
    {
        path: 'phone-login',
        loadChildren: () => PhoneLoginPageModule,
    },
    {
        path: 'verification',
        canActivate: [NotVerifiedGuard],
        loadChildren: () => VerificationPageModule,
    },
    {
        path: 'set-location',
        canActivate: [AuthGuard],
        loadChildren: () => SetLocationPageModule,
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => ProfilePageModule,
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => HomePageModule,
    },

    {
        path: 'order-overview',
        canActivate: [AuthGuard],
        loadChildren: () => OrderOverviewPageModule,
    },
    {
        path: 'order-detail',
        canActivate: [AuthGuard],
        loadChildren: () => OrderDetailPageModule,
    },
    {
        path: 'order-placed',
        canActivate: [AuthGuard],
        loadChildren: () => OrderPlacedPageModule,
    },
    {
        path: 'order-payment',
        canActivate: [AuthGuard],
        loadChildren: () => OrderPaymentPageModule,
    },
    {
        path: 'email-login',
        loadChildren: () => EmailLoginPageModule,
    },
    {
        path: 'register',
        canActivate: [AuthGuard, NotVerifiedGuard],
        loadChildren: () => RegisterPageModule,
    },
    {
        path: 'social-login',
        canActivate: [AuthGuard, NotVerifiedGuard],
        loadChildren: () => SocialLoginPageModule,
    },
    {
        path: 'tabs',
        canActivate: [AuthGuard],
        loadChildren: () => TabsPageModule,
    },
    {
        path: 'place/:id',
        canActivate: [AuthGuard],
        loadChildren: () => PlacePageModule,
    },

    {
        path: 'favorites',
        canActivate: [AuthGuard],
        loadChildren: () => FavoritesPageModule,
    },
    {
        path: 'legal',
        loadChildren: () => LegalPageModule,
    },
    {
        path: 'support',
        loadChildren: () => SupportPageModule,
    },
    {
        path: 'order-cart',
        canActivate: [AuthGuard],
        loadChildren: () => OrderCartPageModule,
    },
    {
        path: 'profile-edit',
        canActivate: [AuthGuard],
        loadChildren: () => ProfileEditPageModule,
    },
    {
        path: 'place-imprint',
        canActivate: [AuthGuard],
        loadChildren: () => PlaceImprintPageModule,
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => SettingsPageModule,
    },
    {
        path: 'profile-paymethods',
        canActivate: [AuthGuard],
        loadChildren: () => ProfilePaymethodsPageModule,
    },
    {
        path: 'confirm-address',
        canActivate: [AuthGuard],
        loadChildren: () => ConfirmAddressPageModule,
    },
    {
        path: 'saved-addresses',
        loadChildren: () => SavedAddressesPageModule,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'top',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

<ion-header translucent="true">
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/tabs/home"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content
    fullscreen
    class="bg_color">
    <app-place-header [place]="place"></app-place-header>
    <ion-card *ngIf="place?.mainTag || place?.tags?.length">
        <ion-card-header>
            <ion-card-title
                class="text-lg font-semibold text-left  tracking-wider leading-none"
                >Kategorie</ion-card-title
            >
        </ion-card-header>

        <ion-card-content>
            <div
                class="mr-3 badge"
                *ngIf="place?.mainTag?.name">
                {{ place?.mainTag?.name }}
            </div>
            <div
                class="mr-3 badge"
                *ngFor="let tag of place?.tags">
                {{ tag?.name || tag }}
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="place?.openingHours?.length">
        <ion-card-header>
            <ion-card-title
                class="text-lg font-semibold text-left  tracking-wider leading-none"
                >Öffnungszeiten</ion-card-title
            >
        </ion-card-header>

        <ion-card-content>
            <div
                class="badge"
                *ngIf="place?.hasOpen">
                Schließt um {{ place?.closesAt | date: 'HH:mm' }} Uhr
            </div>
            <div
                class="badge"
                *ngIf="
          !place?.hasOpen &&
          (place?.opensAt | date: 'd.M.Y') == (now | date: 'd.M.Y')
        ">
                Öffnet heute um {{ place?.opensAt | date: 'HH:mm' }} Uhr
            </div>
            <div
                class="badge"
                *ngIf="
          !place?.hasOpen &&
          (place?.opensAt | date: 'd.M.Y') != (now | date: 'd.M.Y')
        ">
                Öffnet am {{ place?.opensAt | date: 'd.M.Y' }} um {{
                place?.opensAt | date: 'HH:mm' }} Uhr
            </div>
            <div
                class="my-3"
                *ngFor="let openingHour of place?.openingHours">
                <span
                    *ngIf="openingHour.weekday"
                    class="text-xs font-bold  tracking-wider leading-none">
                    {{ 'days.day.' + openingHour.weekday | translate }}</span
                >
                <span
                    *ngIf="openingHour.date"
                    class="text-xs font-bold  tracking-wider leading-none">
                    {{ openingHour.date | date: 'd.M.Y' }}</span
                >
                <ng-container *ngIf="openingHour.open">
                    <div
                        class="text-xs  tracking-wider leading-none"
                        *ngFor="let time of openingHour.times">
                        <span>{{ time.start }}</span> Uhr -
                        <span>{{ time.end }}</span> Uhr
                    </div>
                </ng-container>
                <ng-container *ngIf="!openingHour.open">
                    <div class="text-xs  tracking-wider leading-none">
                        Geschlossen
                    </div>
                </ng-container>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card
        *ngIf="
      place?.address?.address || place?.address?.zipCode || place?.address?.city
    "
        (click)="
      helper.openLink(
        'https://maps.google.com/?daddr=' +
          place?.address?.address +
          ',' +
          place?.address?.zipCode
      )
    ">
        <ion-card-header>
            <ion-card-title
                class="text-lg font-semibold text-left  tracking-wider leading-none"
                >Wegbeschreibung</ion-card-title
            >
        </ion-card-header>

        <ion-card-content class="text-base  leading-none">
            <ion-list lines="none">
                <ion-item
                    button
                    lines="none"
                    class=""
                    detail>
                    {{ place?.address?.address }}<br />
                    {{ place?.address?.zipCode }} {{ place?.address?.city }}
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="place?.phoneNumber || place?.website">
        <ion-card-header>
            <ion-card-title
                class="text-lg font-semibold text-left  tracking-wider leading-none"
                >Kontaktinfos</ion-card-title
            >
        </ion-card-header>

        <ion-card-content>
            <ion-list lines="none">
                <ion-item
                    *ngIf="place?.phoneNumber"
                    (click)="call()"
                    button
                    detail
                    lines="none"
                    class="">
                    <!--<ion-icon name="call-outline" slot="start"></ion-icon>-->
                    <ion-label>{{ place?.phoneNumber }}</ion-label>
                </ion-item>

                <ion-item
                    *ngIf="place?.website"
                    button
                    detail
                    (click)="helper.openLink(place?.website)"
                    lines="none"
                    class="">
                    <!--<ion-icon name="globe-outline" slot="start"></ion-icon>-->
                    <ion-label>{{ place?.website }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="place?.imprint">
        <ion-card-header>
            <ion-card-title
                class="text-lg font-semibold text-left  tracking-wider leading-none"
                >Sonstiges</ion-card-title
            >
        </ion-card-header>

        <ion-card-content>
            <ion-item
                *ngIf="place?.imprint"
                button
                (click)="goToImprint(place?.imprint)"
                lines="none"
                class="font-semibold text-left text-xs  tracking-wider leading-none">
                <ion-label>Impressum</ion-label>
            </ion-item>
        </ion-card-content>
    </ion-card>
    <!--
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="navigate()">
      <ion-icon name="navigate-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  -->
</ion-content>

import { Component, OnInit } from '@angular/core';
import { HelperService } from '@paystory/services/helper.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.page.html',
    styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
    constructor(public helper: HelperService) {}

    ngOnInit() {}
}

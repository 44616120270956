import { LoadingController, NavController } from '@ionic/angular';
import { Component } from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { ApiService } from '@paystory/services/api.service';
import { HelperService } from '@paystory/services/helper.service';
import { Toast } from '@capacitor/toast';

@Component({
    selector: 'app-register',
    templateUrl: './register.page.html',
    styleUrls: ['./register.page.scss'],
})
export class RegisterPage {
    errors: { [key: string]: string[] };

    registerForm = new UntypedFormGroup({
        address: new UntypedFormGroup({
            name: new UntypedFormControl(
                this.api.currentUserValue?.address?.name,
                Validators.required
            ),
        }),
        email: new UntypedFormControl(this.api.currentUserValue?.email, [
            Validators.email,
            Validators.required,
        ]),
        phoneNumber: new UntypedFormControl(
            {
                value: this.api.currentUserValue?.mobileNumber,
                disabled: this.api.currentUserValue?.mobileNumber,
            },
            Validators.required
        ),
    });

    get name() {
        return this.registerForm.get('name');
    }
    get email() {
        return this.registerForm.get('email');
    }
    get phoneNumber() {
        return this.registerForm.get('phoneNumber');
    }

    constructor(
        private helper: HelperService,
        private navCtrl: NavController,
        private loadingCtrl: LoadingController,
        public api: ApiService
    ) {}

    async register() {
        this.helper.validateAllFormFields(this.registerForm);

        if (!this.registerForm.valid) {
            Toast.show({ text: 'Bitte fülle alle Felder aus.' });
            return;
        }

        const message = 'Speichere ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        delete this.errors;
        await loading.present();
        try {
            await this.api.updateMyself(this.registerForm.value);
            await loading.dismiss();
            await this.navCtrl.navigateRoot('/');
        } catch (e) {
            this.errors = e.error;
            await loading.dismiss();
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { HelperService } from '@paystory/services/helper.service';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.page.html',
    styleUrls: ['./legal.page.scss'],
})
export class LegalPage implements OnInit {
    constructor(public helper: HelperService) {}

    ngOnInit() {}
}

import { Injectable } from '@angular/core';
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    public token: Observable<Token>;
    private tokenSubject: BehaviorSubject<Token>;

    constructor() {
        this.tokenSubject = new BehaviorSubject<Token>(null);
        this.token = this.tokenSubject.asObservable();
    }

    async init() {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
            console.log('registration', token);
            this.tokenSubject.next(token);
            // alert('Push registration success, token: ' + token.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('registrationError', error);
            // alert('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('pushNotificationReceived', notification);
                //  alert('Push received: ' + JSON.stringify(notification));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('pushNotificationActionPerformed', notification);
                // alert('Push action performed: ' + JSON.stringify(notification));
            }
        );

        let permStatus = await PushNotifications.checkPermissions();
        console.log('PushNotifications.checkPermissions', permStatus);

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
            console.log('PushNotifications.requestPermissions', permStatus);
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    }
}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Warenkorb</ion-title>
    </ion-toolbar>
    <button
        class="btn normal-case flex items-center justify-start rounded-none w-full">
        <div class="-mt-1 gap-4 flex items-center justify-start">
            Bestelltyp: {{ 'type.' + orderType | translate }}
        </div>
    </button>
</ion-header>

<ion-content [style.bottom.px]="keyboardSize">
    <div class="p-5 pb-0 pt-2">
        <div class="list-header pb-3">Zusammenfassung</div>
        <ng-container
            *ngIf="cartService.products | async as orderItems; else loading">
            <app-product-item
                class="block mb-3"
                *ngFor="let orderItem of orderItems"
                [orderItem]="orderItem"></app-product-item>
        </ng-container>

        <loading-item
            *ngIf="(cartService.products | async)?.length === 0"
            class="block mb-5"
            [loading]="false"
            [message]="'Keine Produkte im Warenkorb'"></loading-item>

        <ng-template #loading>
            <loading-item [loading]="true"></loading-item
        ></ng-template>
    </div>

    <div
        class="px-5 py-2"
        *ngIf="cartService.data?.type === 'delivery'">
        <div class="list-header">Lieferadresse</div>

        <div
            *ngIf="cartService.data?.deliveryAddress"
            class="custom-shadow sum item-border border border-gray-200">
            <ion-item>
                <ion-label position="stacked"
                    >Wohin dürfen wir liefern?</ion-label
                >
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.company"
                    [placeholder]="'Address.Company' | translate"></ion-input>
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.name"
                    [placeholder]="'Address.Name' | translate"></ion-input>
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.address"
                    [placeholder]="'Address.Address' | translate"></ion-input>
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.zipCode"
                    [placeholder]="'Address.Zip Code' | translate"></ion-input>
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.city"
                    [placeholder]="'Address.City' | translate"></ion-input>
                <ion-input
                    (ionBlur)="cartService.saveData()"
                    [(ngModel)]="cartService.data.deliveryAddress.phoneNumber"
                    [placeholder]="'Address.Phone Number' | translate"></ion-input>
            </ion-item>
        </div>
    </div>

    <div
        class="px-5 py-2"
        *ngIf="(cartTotal$ | async) > 0">
        <div class="list-header">Trinkgeld</div>
        <app-tip
            [total]="cartTotal$ | async"
            [tipAmount]="cartService.data?.tip"
            (tipChange)="cartService.setTip($event)"></app-tip>
    </div>

    <div class="px-5 py-2">
        <div class="list-header">Sonstiges</div>
        <div>
            <ion-item
                lines="none"
                class="table-number custom-shadow item-border"
                *ngIf="cartService.data?.type === 'order'">
                <ion-icon
                    slot="start"
                    name="location-outline"></ion-icon>
                <ion-label>Tischnummer</ion-label>

                <ion-input
                    slot="end"
                    class="w-10 h-10 rounded-md focus:outline-none hover:outline-none bg-gray-100 text-center !p-0"
                    type="tel"
                    *ngIf="cartService.data?.details"
                    [(ngModel)]="cartService.data.details.tableNumber"
                    (ionBlur)="setTableNumber(cartService.data?.details?.tableNumber)"></ion-input>
            </ion-item>
        </div>
        <ion-item
            lines="none"
            class="custom-shadow item-border mt-2">
            <ion-textarea
                class="order-notes"
                [(ngModel)]="cartService.data.notes"
                autoGrow="true"
                rows="1"
                placeholder="Anmerkung"></ion-textarea>
        </ion-item>
    </div>

    <div class="px-5 py-2">
        <div class="list-header">{{ 'apply_coupon' | translate }}</div>

        <ion-item class="custom-shadow item-border">
            <ion-input
                [readonly]="discountLoading"
                type="text"
                [placeholder]="'DE172118'"
                [(ngModel)]="discountCode"></ion-input>
            <ion-spinner
                *ngIf="discountLoading"
                slot="end"></ion-spinner>
            <ion-button
                size="small"
                fill="clear"
                (click)="checkCoupon()"
                *ngIf="!discountLoading"
                slot="end">
                {{ 'apply' | translate }}
            </ion-button>
        </ion-item>
    </div>
<div class="px-5 py-2">

    <div class="list-header">Zahlungsmittel</div>
    <app-payment-method
        *ngIf="placeService.activePlace?.allowedPaymentMethods"
        (onChange)="cartService.selectPaymentMethod($event)"
        [paymentMethod]="cartService.data.paymentMethod"
        [paymentMethods]="placeService.activePlace?.allowedPaymentMethods"></app-payment-method>
</div>

    <div class="px-5 py-2 ">
        <div class="list-header">Zahlungsinformationen</div>

        <div class="custom-shadow sum item-border border border-gray-200">
            <ion-item
                lines="none"
                class="bg-card rounded overflow-hidden -mb-2">
                <ion-label> Zwischensumme </ion-label>
                <div class="badge">
                    {{ cartTotal$ | async | currency: 'EUR' }}
                </div>
            </ion-item>
            <ion-item
                lines="none"
                class="bg-card rounded overflow-hidden -mb-2"
                *ngIf="cartService.data?.tip > 0 && (cartTotal$ | async) > 0">
                <ion-label> Trinkgeld </ion-label>
                <div class="badge">
                    {{ (cartTip$ | async).toFixed() }}% / {{
                    cartService.data?.tip | currency: 'EUR' }}
                </div>
            </ion-item>
            <ion-item
                lines="none"
                class="bg-card rounded overflow-hidden mb-0">
                <ion-label>Gebühren</ion-label>
                <div class="badge">
                    {{ cartFees$ | async | currency: 'EUR' }}
                </div>
            </ion-item>
            <ion-item
                lines="none"
                class="summary bg-card rounded overflow-hidden -mt-2">
                <ion-label>Summe</ion-label>
                <div class="badge">
                    {{ cartTotalWithFee$ | async | currency: 'EUR' }}
                </div>
            </ion-item>
        </div>
    </div>

    <div
        class="px-5 py-2"
        *ngIf="!(cartService.products | async)?.length">
        <ion-button
            expand="full"
            class="rounded-md overflow-hidden"
            (click)="back()"
            >Zurück zu den Produkten</ion-button
        >
    </div>
    <div
        class="px-5 py-2 mb-28"
        [hidden]="!(cartService.products | async)?.length">
        <div class="relative block h-12">
            <div
                #track
                class="track_drag animate">
                <div
                    #dragItem
                    class="knob">
                    <ion-icon name="wallet-outline"></ion-icon>
                </div>
                <p
                    #end
                    class="track_text track_text--end animate"
                    style="font-size: 40px">
                    <ion-icon name="shield-checkmark-outline"></ion-icon>
                </p>
                <p
                    #after
                    class="track_text track_text--after animate"></p>
                <div
                    #before
                    class="track_text track_text--before animate flex-col leading-3">
                    <div class="font-bold mt-2">
                        {{ cartTotalWithFee$ | async | currency: 'EUR' }}
                    </div>
                    <div class="text-xs opacity-60">Jetzt kaufen</div>
                </div>
            </div>
        </div>
        <div class="w-full text-center text-gray-500 text-xs uppercase mt-5">
            Ziehen zum kaufen
            <div>
                <small
                    >Ich bestätige das ich die AGBs gelesen habe und diesen
                    zustimme</small
                >
            </div>
        </div>
    </div>
</ion-content>

import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Stripe } from '@capacitor-community/stripe';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import * as ImgCache from '@chrisben/imgcache.js';
import { ApiService } from '@paystory/services/api.service';
import { DeviceService } from '@paystory/services/device.service';
import { HelperService } from '@paystory/services/helper.service';
import { PushNotificationService } from '@paystory/services/push.service';
import { CacheService } from 'ionic-cache';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    public appPages: any = [
        { title: 'Bestellen', url: '/tabs/home', icon: 'home-outline' },
        { title: 'Bestellungen', url: '/tabs/orders', icon: 'albums-outline' },
        // { title: 'Favoriten', url: '/favorites', icon: 'heart-outline' },
        // { divider: true },
        { title: 'Profil', url: '/tabs/profile', icon: 'person-outline' },
        /*{
            title: 'Benötigst du Hilfe?',
            url: '/support',
            icon: 'information-circle-outline',
        },*/
        // { title: 'Einstellungen', url: '/settings', icon: 'settings-outline' },
        // { divider: true },
    ];

    developerMode = false;

    constructor(
        public deviceService: DeviceService,
        public apiService: ApiService,
        private zone: NgZone,
        private helperService: HelperService,
        private http: HttpClient,
        private pushService: PushNotificationService,
        private cache: CacheService
    ) {}

    async ngOnInit() {
        ImgCache.options.debug = false;
        ImgCache.options.chromeQuota = 200 * 1024 * 1024; // 200 MB
        ImgCache.init(
            data => {
                console.log('ImgCache init: success!', data);
            },
            err => {
                console.log(
                    'ImgCache init: error! Check the log for errors',
                    err
                );
            }
        );

        this.cache.setDefaultTTL(600);
        this.helperService.getDarkMode();
        // await Keyboard.setScroll({ isDisabled: false });
        DeviceService.initialized.then((mode: boolean) => {
            this.developerMode = mode;

            lastValueFrom(
                this.http.get<string>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/stripe/public_key`
                )
            ).then(stripeKey => {
                Stripe.initialize({
                    publishableKey: stripeKey,
                });
            });
        });

        await App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            this.zone.run(() => {
                this.helperService.appUrlOpen(data.url);
            });
        });

        if (Capacitor.isNativePlatform()) {
            // Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
            SplashScreen.hide();
            StatusBar.setStyle({
                style: Style.Dark,
            });

            /*StatusBar.setOverlaysWebView({
        overlay: true,
      });*/

            try {
                this.pushService.init();
            } catch (_) {
                console.warn(_);
            }
        }
    }

    support() {
        this.helperService.openLink('https://paystory.de/kontakt/');
    }
}

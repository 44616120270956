<div *ngFor="let productOptionGroup of item.orderItemOptionGroups">
    <div
        *ngFor="let productOption of productOptionGroup.orderItemOptions"
        class="font-semibold text-left text-xs  tracking-wider leading-none">
        {{ productOption.title }} {{ productOption.price | currency: 'EUR' }}
    </div>
</div>
<div *ngFor="let selectedProductOption of selectedProductOptions">
    <app-order-cart-product-option-group
        [item]="selectedProductOption"></app-order-cart-product-option-group>
</div>

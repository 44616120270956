<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Profil</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="animate__animated animate__fadeInUp p-5 pb-0">
        <h2 class="font-bold gray-gradient text-4xl -ml-1">
            {{ user.address?.name }}
        </h2>

        <p class="font-bold text-xs text-secondary tracking-wider">
            {{ user.email }}
        </p>
        <p class="font-bold text-xs text-secondary tracking-wider">
            {{ user.mobileNumber }}
        </p>
    </div>
    <div
        class="animate__animated animate__fadeInUp edit flex flex-end items-start justify-end p-5 pt-0">
        <button
            class="btn btn-sm normal-case"
            [routerLink]="'/profile-edit'">
            Bearbeiten
        </button>
    </div>

    <div class="animate__animated animate__fadeInUp">
        <ion-list-header> Allgemein </ion-list-header>
        <ion-list class="bg-card overflow-hidden">
            <!-- <ion-item button (click)="favorites()">
        <ion-icon slot="start" name="heart-outline"></ion-icon>
        <h2>{{'favorites' | translate}}</h2>
      </ion-item>-->

            <ion-item
                button
                (click)="paymethods()">
                <ion-icon
                    slot="start"
                    name="wallet-outline"></ion-icon>
                <ion-label>{{ 'Paymethods' | translate }}</ion-label>
            </ion-item>

            <ion-item
                button
                (click)="settings()">
                <ion-icon
                    slot="start"
                    name="settings-outline"></ion-icon>
                <ion-label>{{ 'settings' | translate }}</ion-label>
            </ion-item>

            <ion-item
                button
                lines="none"
                (click)="savedAddresses()">
                <ion-icon
                    slot="start"
                    name="location-outline"></ion-icon>
                <ion-label>{{ 'saved_addresses' | translate }}</ion-label>
            </ion-item>
        </ion-list>
    </div>

    <div class="animate__animated animate__fadeInUp">
        <ion-list-header> {{ 'other' | translate }} </ion-list-header>
        <ion-list class="bg-card overflow-hidden">
            <ion-item
                button
                (click)="legal()">
                <ion-icon
                    slot="start"
                    name="library-outline"></ion-icon>
                <h2>{{ 'legal' | translate }}</h2>
            </ion-item>
            <ion-item
                button
                (click)="feedback()">
                <ion-icon
                    slot="start"
                    name="chatbubbles-outline"></ion-icon>
                <ion-label>{{ 'feedback' | translate }}</ion-label>
            </ion-item>
            <ion-item
                lines="none"
                button
                (click)="support()">
                <ion-icon
                    slot="start"
                    name="help-buoy-outline"></ion-icon>
                <ion-label>{{ 'support' | translate }}</ion-label>
            </ion-item>
        </ion-list>

        <div class="w-full flex items-center justify-center mt-8">
            <button
                class="btn btn-error btn-sm normal-case"
                (click)="logout()">
                Abmelden
            </button>
        </div>

        <app-version
            class="flex items-center justify-center text-center"></app-version>
    </div>
</ion-content>

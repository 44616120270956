import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-phone-login',
    templateUrl: './phone-login.page.html',
    styleUrls: ['./phone-login.page.scss'],
})
export class PhoneLoginPage implements OnInit {
    constructor() {}

    ngOnInit() {}
}

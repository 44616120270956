import { OrderItemComponent } from './order-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [TranslateModule, CommonModule, FormsModule, IonicModule],
    exports: [OrderItemComponent],
    declarations: [OrderItemComponent],
})
export class OrderItemComponentModule {}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Support</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content> </ion-content>

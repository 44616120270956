import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingItemModule } from '@paystory/components/loading-item';
import { ParallaxHeaderModule } from '@paystory/directives/parallax-header/parallax-header.module';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { VariationSelectionPageModule } from '../../components/variation-selection/variation-selection.module';
import { PlacePageRoutingModule } from './place-routing.module';
import { PlacePage, PlacePopoverPage } from './place.page';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        PlacePageRoutingModule,
        VariationSelectionPageModule,

        ParallaxHeaderModule,
        DistancePipeModule,
        LoadingItemModule,
    ],
    declarations: [PlacePage, PlacePopoverPage],
})
export class PlacePageModule {}

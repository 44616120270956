import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toast } from '@capacitor/toast';
import {
    AlertController,
    ModalController,
    ToastController,
} from '@ionic/angular';
import { Product } from '@paystory/models';
import { CartService } from '@paystory/services/cart.service';
import { HelperService } from '@paystory/services/helper.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-variation-selection',
    templateUrl: './variation-selection.page.html',
    styleUrls: ['./variation-selection.page.scss'],
})
export class VariationSelectionPage implements OnInit {
    @Input() product: Product;
    @Output() onClose = new EventEmitter<void>();

    addLoading = false;

    constructor(
        public modalController: ModalController,
        private cartService: CartService,
        private helper: HelperService,
        private alertController: AlertController
    ) {}

    ngOnInit() {}

    async dismiss() {
        //await this.modalController.dismiss();
        this.onClose.emit();
    }

    async add() {
        if (this.addLoading) {
            return;
        }
        try {
            this.addLoading = true;

            await this.cartService.addProduct(this.product);

            await this.helper.showToast('Artikel zur Bestellung hinzugefügt.');

            await this.dismiss();
        } catch (error) {
            await this.helper.showToast(
                'Artikel konnte nicht hinzugefügt werden.'
            );
        } finally {
            this.addLoading = false;
        }
    }

    async showAllergens(product: Product) {
        let message = '';
        product.allergens.forEach(allergen => {
            message += `${allergen.code} - ${allergen.name}\n`;
        });

        const alert = await this.alertController.create({
            header: 'Allergene',
            message: message,
            buttons: ['Schließen'],
        });

        await alert.present();

        /*Swal.fire({
            title: 'Allergene',
            text: message,
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: false,
            showCloseButton: false,
            backdrop: true,
        });*/
    }
}

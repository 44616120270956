<ion-tabs>
    <ion-tab-bar
        translucent="true"
        slot="bottom">
        <ion-tab-button tab="home">
            <ion-icon name="home-outline"></ion-icon>
            <ion-label>{{ 'home' | translate }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="orders">
            <ion-icon name="albums-outline"></ion-icon>
            <ion-label>Bestellungen</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile">
            <ion-icon name="person-outline"></ion-icon>
            <ion-label>{{ 'profile' | translate }}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>

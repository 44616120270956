<ion-tabs>
    <ion-tab-bar
        translucent="true"
        slot="bottom">
        <ion-tab-button tab="products">
            <ion-icon name="cart-outline"></ion-icon>
            <ion-label>Produkte</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="info">
            <ion-icon name="home-outline"></ion-icon>
            <ion-label>Info</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>

import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import {
    AlertController,
    NavController,
    ToastController,
} from '@ionic/angular';
import { injectStyles } from 'shadow-dom-inject-styles';
import { get, set } from './storage.service';
import { MagicCodeService } from '@paystory/services/magic-code.service';
import { TranslateService } from '@ngx-translate/core';

declare let window: any;

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    darkModeEnabled = false;
    loadedScripts: string[] = [];

    constructor(
        private navCtrl: NavController,
        private magicCodeService: MagicCodeService,
        private toastCtrl: ToastController,
        private alertController: AlertController,
        private translate: TranslateService
    ) {}

    async getDarkMode() {
        const darkMode = await get('darkMode');

        if (darkMode) {
            this.darkModeEnabled = true;
        } else {
            this.darkModeEnabled = false;
        }

        this.setDarkMode(this.darkModeEnabled);
        return this.darkModeEnabled;
    }

    async setDarkMode(enabled: boolean) {
        await set('darkMode', enabled);
        this.darkModeEnabled = enabled;
        if (enabled) {
            document.body?.classList?.add('dark');
            document.querySelector('html').classList.add('dark');

            localStorage.theme = 'dark';
        } else {
            document.body?.classList?.remove('dark');
            document.querySelector('html').classList.remove('dark');
            localStorage.theme = 'light';
        }
    }

    fixIonContentStyle() {
        const content = document.querySelector('ion-content') as HTMLElement;
        const styles = `
    #background-content {
        background: transparent !important;
      }
    `;

        injectStyles(content, '#background-content', styles);
    }

    async openLink(url) {
        await Browser.open({ url });
    }

    validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public loadScript(src, onlyOnce = false) {
        if (onlyOnce && this.loadedScripts.indexOf(src) > -1) {
            return Promise.resolve();
        }
        this.loadedScripts.push(src);
        return new Promise(resolve => {
            const node = document.createElement('script');
            node.src = src;
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
            node.onload = () => {
                resolve(true);
            };
        });
    }

    loadMaps() {
        const mapsUrl =
            'http://maps.google.com/maps/api/js?libraries=places,geometry&key=AIzaSyBCMLmEp-mSfvvI_3LDp9tcx2u4a2xgAgE';
        return this.loadScript(mapsUrl);
    }

    async appUrlOpen(url: string) {
        const slug = url.replace(/^.+?paystory\..+?(\/)/, '$1');
        let match = slug.match(/^\/places?\/([^\/]+)\/?([^\/]+)?/);
        if (match) {
            const details: any = {};
            if (match[2]) {
                details.tableNumber = match[2];
            }
            this.navCtrl.navigateForward(
                ['place', match[1]],
                Object.keys(details).length > 0
                    ? {
                          queryParams: details,
                      }
                    : undefined
            );
        }
        match = slug.match(/^\/magic-?codes?\/([^\/]+)/);
        if (match) {
            try {
                const magicCode = await this.magicCodeService.get(match[1]);
                await this.appUrlOpen(magicCode.action?.url);
            } catch (_) {}
        }
        match = slug.match(/^\/m\/([^\/]+)/);
        if (match) {
            try {
                const magicCode = await this.magicCodeService.get(match[1]);
                await this.appUrlOpen(magicCode.action?.url);
            } catch (_) {}
        }
    }

    async showToast(message) {
        const toast = await this.toastCtrl.create({
            message,
            translucent: true,
            position: 'top',
            duration: 5000,
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
        });
        toast.present();
    }

    confirmDelete(
        message = 'Möchten Sie diesen Eintrag wirklich löschen?',
        header = 'Löschen',
        button = 'Löschen'
    ) {
        return new Promise(async (resolve, reject) => {
            const alert = await this.alertController.create({
                header,
                message,
                translucent: true,
                buttons: [
                    {
                        text: this.translate.instant('Abbrechen'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                            reject();
                        },
                    },
                    {
                        text: button,
                        role: 'delete',
                        cssClass: 'danger',
                        handler: async data => {
                            resolve(true);
                        },
                    },
                ],
            });
            await alert.present();
        });
    }
}

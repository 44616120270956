import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoadingItemComponent } from './loading-item.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [LoadingItemComponent],
    exports: [LoadingItemComponent],
})
export class LoadingItemModule {}

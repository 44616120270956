import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VerificationPageRoutingModule } from './verification-routing.module';

import { VerificationPage } from './verification.page';
import { CodeInputModule } from '@paystory/components/code-input/code-input.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        VerificationPageRoutingModule,
        CodeInputModule,
    ],
    declarations: [VerificationPage],
})
export class VerificationPageModule {}

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingItemModule } from '@paystory/components/loading-item/loading-item.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlaceProductsPageRoutingModule } from './place-products-routing.module';

import { PlaceProductsPage } from './place-products.page';
import { ParallaxHeaderModule } from '@paystory/directives/parallax-header/parallax-header.module';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { UpDownModule } from '@paystory/components/up-down/up-down.module';
import { PlaceHeaderModule } from 'src/pages/place/components/place-header/place-header.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VariationSelectionPageModule } from 'src/pages/place/components/variation-selection/variation-selection.module';

@NgModule({
    imports: [
        UpDownModule,
        CommonModule,
        FormsModule,
        IonicModule,
        PlaceProductsPageRoutingModule,
        LoadingItemModule,
        TranslateModule,
        ParallaxHeaderModule,
        DistancePipeModule,
        PlaceHeaderModule,
        LazyLoadImageModule,
        ScrollingModule,
        VariationSelectionPageModule,
    ],
    declarations: [PlaceProductsPage],
})
export class PlaceProductsPageModule {}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button [defaultHref]="'/'"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ 'saved_addresses' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-refresher
        slot="fixed"
        (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div
        *ngIf="!savedAddresses?.length"
        class="flex items-center justify-center my-5">
        Keine Adresse vorhanden
    </div>

    <ion-card
        *ngFor="let address of savedAddresses; trackBy: trackBy; let i=index">
        <google-map
            [height]="'120px'"
            [options]="{ disableDefaultUI: true }"
            class="pointer-events-none"
            [center]="{lat: address.latitude, lng: address.longitude}">
            <map-marker
                [position]="{lat: address.latitude, lng: address.longitude}"></map-marker>
        </google-map>
        <ion-card-header>
            <ion-card-title>
                <ng-container *ngIf="!address.edit">
                    {{ address.name }}
                </ng-container>

                <input
                    *ngIf="address.edit"
                    class="input input-bordered input-md w-full"
                    [(ngModel)]="address.name"
                    type="text"
                    placeholder="Name (Klingel)" />
            </ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ng-container *ngIf="!address.edit">
                {{ address.address }} <br />
                {{ address.zipCode }} {{ address.city }}<br />
                {{ address.phoneNumber }} <br />
                <div
                    class="flex items-center"
                    *ngIf="address.deliveryNotes">
                    <ion-icon
                        name="reader-outline"
                        class="mr-2"></ion-icon>
                    {{ address.deliveryNotes }}
                    <!--{{ address.deliveryNotes ? address.deliveryNotes : 'No rider
          directions' }}-->
                </div>
            </ng-container>
            <div
                class="grid gap-2"
                *ngIf="address.edit">
                <input
                    class="input input-bordered input-xs w-full"
                    [(ngModel)]="address.address"
                    placeholder="Adresse" />
                <div class="input-group input-group-sm">
                    <input
                        class="input input-bordered input-xs w-full"
                        [(ngModel)]="address.zipCode"
                        type="number"
                        placeholder="PLZ" />
                    <input
                        class="input input-bordered input-xs w-full"
                        [(ngModel)]="address.city"
                        placeholder="Ort" />
                </div>
                <input
                    class="input input-bordered input-xs w-full"
                    [(ngModel)]="address.phoneNumber"
                    title="Bitte eine korrekte Telefonnummer eingeben"
                    placeholder="Telefonnummer" />
                <textarea
                    class="textarea textarea-bordered textarea-xs w-full"
                    [(ngModel)]="address.deliveryNotes"
                    placeholder="Hinweise für die Lieferung"></textarea>
            </div>
        </ion-card-content>

        <div class="flex flex-row justify-between mx-2">
            <div>
                <button
                    class="btn btn-sm"
                    *ngIf="!address.default && !address.edit"
                    color="info"
                    size="small"
                    fill="outline"
                    (click)="default(address)">
                    Als Standard
                </button>
            </div>

            <div class="btn-group py-2">
                <button
                    class="btn btn-error btn-sm"
                    *ngIf="!address.default && !address.edit"
                    color="danger"
                    size="small"
                    fill="outline"
                    (click)="remove(address)">
                    Löschen</button
                ><button
                    class="btn btn-sm"
                    *ngIf="!address.edit"
                    color="primary"
                    size="small"
                    (click)="onChange(address)">
                    Ändern
                </button>
                <button
                    class="btn btn-sm"
                    *ngIf="address.edit"
                    color="danger"
                    size="small"
                    (click)="onCancel(i,address)">
                    Abbrechen
                </button>
                <button
                    class="btn btn-sm"
                    *ngIf="address.edit"
                    color="primary"
                    size="small"
                    (click)="save(address)">
                    Speichern
                </button>
            </div>
        </div>
    </ion-card>

    <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed">
        <ion-fab-button [routerLink]="['/set-location']">
            <ion-icon name="add-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '@paystory/models';
import { first, map } from 'rxjs/operators';
import {
    BehaviorSubject,
    firstValueFrom,
    lastValueFrom,
    Observable,
} from 'rxjs';
import { DeviceService } from '@paystory/services/device.service';
import { PushNotificationService } from '@paystory/services/push.service';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';

export class StripePaymentMethod {
    id?: string;
    object?: string;
    billing_details?: {
        address?: {
            city?: any;
            country?: any;
            line1?: any;
            line2?: any;
            postal_code?: any;
            state?: any;
        };
        email?: any;
        name?: any;
        phone?: any;
    };
    card?: {
        brand?: string;
        checks?: {
            address_line1_check?: any;
            address_postal_code_check?: any;
            cvc_check?: any;
        };
        country?: string;
        exp_month?: number;
        exp_year?: number;
        fingerprint?: string;
        funding?: string;
        generated_from?: any;
        last4?: string;
        networks?: {
            available?: string[];
            preferred?: any;
        };
        three_d_secure_usage?: {
            supported?: boolean;
        };
        wallet?: any;
    };
    created?: number;
    customer?: any;
    livemode?: boolean;
    type?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    static USER_SAVE_KEY = 'PayStory-User';
    static OAUTH_CLIENT_ID;
    static OAUTH_CLIENT_SECRET;

    public currentUser: Observable<User>;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(
        private http: HttpClient,
        private push: PushNotificationService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem(ApiService.USER_SAVE_KEY))
        );
        this.currentUser = this.currentUserSubject.asObservable();

        DeviceService.initialized.then(() => {
            ApiService.OAUTH_CLIENT_ID =
                DeviceService.getEnvironmentParameter('oauthClientId');
            ApiService.OAUTH_CLIENT_SECRET =
                DeviceService.getEnvironmentParameter('oauthClientSecret');

            Sentry.setUser(
                this.currentUserValue?.id
                    ? {
                          id: this.currentUserValue?.id,
                          username: this.currentUserValue?.displayName,
                          email: this.currentUserValue?.email,
                      }
                    : null
            );
        });
    }

    /**
     * All the user stuff
     */

    public refreshUser() {
        this.currentUserSubject.next(
            JSON.parse(localStorage.getItem(ApiService.USER_SAVE_KEY))
        );
    }

    public setCurrentUserValue(data: any = null) {
        if (data === null) {
            Sentry.setUser(null);
            localStorage.removeItem(ApiService.USER_SAVE_KEY);
        } else {
            Sentry.setUser(
                data?.id
                    ? {
                          id: data?.id,
                          username: data?.displayName,
                          email: data?.email,
                      }
                    : null
            );
            localStorage.setItem(
                ApiService.USER_SAVE_KEY,
                JSON.stringify(data)
            );
        }
        this.refreshUser();
    }

    public async addCurrentUserValue(data: any = null) {
        this.setCurrentUserValue(
            Object.assign(this.currentUserSubject.value || {}, data)
        );
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    refreshToken() {
        return this.http
            .post<any>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/oauth/v2/token`,
                {
                    grant_type: 'refresh_token',
                    refresh_token: this.currentUserValue.refresh_token,
                    client_id: ApiService.OAUTH_CLIENT_ID,
                    client_secret: ApiService.OAUTH_CLIENT_SECRET,
                    locale: navigator.language.replace('-', '_'),
                }
            )
            .pipe(
                map(user => {
                    this.addCurrentUserValue(user);
                    return user;
                })
            );
    }

    /**
     * Registration and login
     */

    async registerWithMobileNumber(
        data: {
            mobileNumber: string;
        },
        resend: boolean = true,
        code?: number
    ) {
        const response = await lastValueFrom(
            this.http
                .post<{
                    confirmed?: boolean;
                }>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/register${
                        typeof code !== 'undefined' ? `?code=${code}` : ''
                    }`,
                    {
                        ...data,
                        client_id: ApiService.OAUTH_CLIENT_ID,
                        locale: navigator.language.replace('-', '_'),
                        resend,
                    }
                )
                .pipe(
                    map(s => {
                        if (s.hasOwnProperty('access_token')) {
                            this.loginWithAccessToken(s);
                        }
                        return s;
                    })
                )
        );
        if (this.currentUserValue?.access_token?.length > 0) {
            await this.getMyself();
        }
        return response;
    }

    async registerWith(service: 'facebook' | 'apple', accessToken?: string) {
        const response = await lastValueFrom(
            this.http
                .post<{
                    confirmed?: boolean;
                }>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/login/${service}`,
                    {
                        access_token: accessToken,
                        client_id: ApiService.OAUTH_CLIENT_ID,
                        locale: navigator.language.replace('-', '_'),
                    }
                )
                .pipe(
                    map(s => {
                        if (s.hasOwnProperty('access_token')) {
                            this.loginWithAccessToken(s);
                        }
                        return s;
                    })
                )
        );
        if (this.currentUserValue?.access_token?.length > 0) {
            await this.getMyself();
        }
        return response;
    }

    async loginWithAccessToken(data: any) {
        if (data?.access_token) {
            await this.addCurrentUserValue(data);

            try {
                const token = await firstValueFrom(this.push.token);
                if (token?.value) {
                    await this.addUserNotificationChannel(token.value);
                }
            } catch (_) {
                console.warn(_);
            }
        }
    }

    async addUserNotificationChannel(token: string) {
        return await lastValueFrom(
            this.http.post<{ success: boolean }>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/user_notification_channels`,
                {
                    token,
                    type: Capacitor.getPlatform(),
                }
            )
        );
    }

    async deleteUserNotificationChannel(token: string) {
        return await lastValueFrom(
            this.http.delete<{ success: boolean }>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/user_notification_channels`,
                {
                    body: {
                        token,
                    },
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                    }),
                }
            )
        );
    }

    async getMyself() {
        return await lastValueFrom(
            this.http
                .get<any>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/users/me`
                )
                .pipe(
                    map(user => {
                        this.addCurrentUserValue(user);
                        return user;
                    })
                )
        );
    }

    async updateMyself(data: any) {
        return await lastValueFrom(
            this.http
                .post<any>(
                    `${DeviceService.getEnvironmentParameter(
                        'api'
                    )}/api/users/me`,
                    data
                )
                .pipe(
                    map(user => {
                        this.addCurrentUserValue(user);
                        return user;
                    })
                )
        );
    }

    async getMyPaymentMethods(): Promise<StripePaymentMethod[]> {
        return lastValueFrom(
            this.http.get<StripePaymentMethod[]>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/users/me/payment_methods`
            )
        );
    }

    async deleteMyPaymentMethod(id: string): Promise<boolean> {
        return lastValueFrom(
            this.http.delete<boolean>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/users/me/payment_methods/${id}`
            )
        );
    }

    logout() {
        this.setCurrentUserValue();
        this.push.token.pipe(first()).subscribe(token => {
            if (token?.value) {
                this.deleteUserNotificationChannel(token.value);
            }
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Order } from '@paystory/models/order.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DeviceService } from '@paystory/services/device.service';
import { Browser } from '@capacitor/browser';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.page.html',
    styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit {
    orderId?: string;
    fabAction = false;
    @Input() order: Order;

    constructor(
        private modalController: ModalController,
        private route: ActivatedRoute,
        private http: HttpClient,
        private loadingCtrl: LoadingController
    ) {}

    async ngOnInit() {
        const loading = await this.loadingCtrl.create();
        loading.present();
        try {
            this.orderId = this.route.snapshot.params.orderId;
            if (!this.order && this.orderId) {
                this.order = await this.http
                    .get<Order>(
                        `${DeviceService.getEnvironmentParameter(
                            'api'
                        )}/api/orders/${this.orderId}`
                    )
                    .toPromise();
            }
            console.log(this.order);
        } catch (error) {
        } finally {
            loading.dismiss();
        }
    }

    toggleFab() {
        this.fabAction = !this.fabAction;
    }

    downloadInvoice() {
        // TODO Add Invoice URL
        Browser.open({
            url: `${DeviceService.getEnvironmentParameter('api')}/api/orders/${
                this.orderId
            }`,
            presentationStyle: 'popover',
        });
    }

    getPayMethod(paymentMethod) {
        if (!paymentMethod.startsWith('pm_')) {
            return 'paymentMethod.' + paymentMethod;
        }
    }
}

import { PlaceService } from '@paystory/services/place.service';
import { Place } from '@paystory/models';
import { Component, OnInit } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { ModalController, IonRouterOutlet } from '@ionic/angular';
import { HelperService } from '@paystory/services/helper.service';
import { PlaceImprintPage } from '../../../place-imprint/place-imprint.page';

@Component({
    selector: 'app-place-info',
    templateUrl: './place-info.page.html',
    styleUrls: ['./place-info.page.scss'],
})
export class PlaceInfoPage implements OnInit {
    now = new Date();
    place: Place;

    constructor(
        private modalController: ModalController,
        private callNumber: CallNumber,
        private routerOutlet: IonRouterOutlet,
        public helper: HelperService,
        private placeService: PlaceService
    ) {}

    async ngOnInit() {
        if (!this.placeService.activePlace) {
            await this.placeService.loadPlace();
        }
        this.place = this.placeService.activePlace;
    }

    async call() {
        window.open('tel:' + this.placeService.activePlace.phoneNumber);
        /*await this.callNumber.callNumber(
      this.placeService.activePlace.phoneNumber,
      true
    );*/
    }

    async goToImprint(imprint) {
        const modal = await this.modalController.create({
            component: PlaceImprintPage,
            componentProps: {
                imprint,
            },
            presentingElement: this.routerOutlet.nativeEl,
        });
        return await modal.present();
    }

    navigate() {
        /*let options: LaunchNavigatorOptions = {
      start: 'London, ON',
    };

    this.launchNavigator.navigate('Toronto, ON', options).then(
      (success) => console.log('Launched navigator'),
      (error) => console.log('Error launching navigator', error)
    );*/
    }
}

<div class="flex flex-col space-y-3">
    <div
        *ngFor="let method of paymentMethods"
        class="bg-blue-50 rounded flex flex-col items-center justify-center p-3 tip-item"
        (click)="!readonly && activate(method)"
        [class.active]="paymentMethod === method"
        [class.readonly]="readonly">
        <div class="font-bold text-xl">
            <ng-container *ngIf="method === 'stripe' && platform == 'ios'">
                <img class="h-11" src="/assets/icons/payment/applepay.svg" />
                <!-- Apple Pay / Kreditkarte -->
            </ng-container>
            <ng-container
                *ngIf="method === 'stripe' && platform == 'android'">
                <!--Google Pay / Kreditkarte-->
                <img class="h-11" src="/assets/icons/payment/googlepay.svg" />
            </ng-container>

            <ng-container *ngIf="method === 'stripe' && platform == 'web'">
                Kreditkarte
            </ng-container>

            <ng-container *ngIf="method !== 'stripe'">
                {{ 'paymentMethod.' + method | translate }}
            </ng-container>
        </div>
    </div>
</div>

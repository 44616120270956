import { LoadingController, NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '@paystory/services/api.service';
@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.page.html',
    styleUrls: ['./social-login.page.scss'],
})
export class SocialLoginPage implements OnInit {
    phoneNumber: string;
    constructor(
        private loadingCtrl: LoadingController,
        private navCtrl: NavController,
        public api: ApiService
    ) {}

    ngOnInit() {}

    async loginWithPhone() {
        const message = 'Login mit deiner Telefonnummer ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });

        let phoneNumber;
        if (this.phoneNumber.toString().startsWith('0')) {
            phoneNumber = this.phoneNumber.toString().trim().substring(1);
        } else if (this.phoneNumber.toString().startsWith('+49')) {
            phoneNumber = this.phoneNumber.toString().trim().substring(3);
        } else {
            phoneNumber = this.phoneNumber.toString().trim();
        }

        await loading.present();
        try {
            const mobileNumber = `+49${phoneNumber}`;
            await this.api.registerWithMobileNumber({
                mobileNumber,
            });
            await loading.dismiss();
            await this.api.addCurrentUserValue({
                mobileNumber,
            });
            await this.navCtrl.navigateForward('/verification');
        } catch (e) {
            await loading.dismiss();
        }
    }
}

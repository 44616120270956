import { LoadingItemModule } from '@paystory/components/loading-item/loading-item.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrderOverviewPage } from './order-overview.page';

import { OrderOverviewPageRoutingModule } from './order-overview-routing.module';
import { OrderItemComponentModule } from '../../components/order-item/order-item.module';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        OrderOverviewPageRoutingModule,
        OrderItemComponentModule,
        LoadingItemModule,
    ],
    declarations: [OrderOverviewPage],
})
export class OrderOverviewPageModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConfirmAddressPage } from './confirm-address.page';
import { ConfirmAddressPageRoutingModule } from './confirm-address-routing.module';

@NgModule({
    declarations: [ConfirmAddressPage],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ConfirmAddressPageRoutingModule,
    ],
})
export class ConfirmAddressPageModule {}

import { UpDownModule } from '../up-down/up-down.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { TipComponent } from './tip.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [TipComponent],
    exports: [TipComponent],
})
export class TipModule {}

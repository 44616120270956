<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Profil</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form
        class="mt-5"
        [formGroup]="registerForm"
        autocomplete="off">
        <ion-item
            class="p-2 item-border"
            formGroupName="address">
            <ion-icon
                slot="start"
                name="person-outline"></ion-icon>
            <ion-label position="floating">Name</ion-label>
            <ion-input
                formControlName="name"
                type="name"
                required></ion-input>
        </ion-item>

        <ion-item class="p-2 item-border">
            <ion-icon
                slot="start"
                name="mail-outline"></ion-icon>
            <ion-label position="floating">E-Mail Adresse</ion-label>
            <ion-input
                formControlName="email"
                type="email"
                placeholder="johndoe@mail.com"
                required></ion-input>
        </ion-item>

        <ion-item class="p-2 item-border">
            <ion-icon
                slot="start"
                name="call-outline"></ion-icon>
            <ion-label position="floating">Telefonnummer</ion-label>
            <ion-input
                formControlName="phoneNumber"
                type="tel"
                placeholder="+49 151 000000"
                required></ion-input>
        </ion-item>
    </form>

    <div class="flex justify-between px-5">
        <div></div>
        <button class="btn" (click)="save()">Speichern</button>
    </div>
</ion-content>

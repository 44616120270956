import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnChanges {
    @Input() readonly = false;
    @Input() paymentMethods: string[];
    @Input() paymentMethod: string;
    @Output() onChange = new EventEmitter<string>();

    platform = Capacitor.getPlatform();

    change() {
        this.onChange.emit(this.paymentMethod);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.paymentMethod && !changes.paymentMethod?.firstChange) {
            this.change();
        }
    }

    activate(paymentMethod: string) {
        this.paymentMethod = paymentMethod;
        this.change();
    }
}

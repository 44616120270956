import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loading-item',
    templateUrl: './loading-item.component.html',
    styleUrls: ['./loading-item.component.scss'],
})
export class LoadingItemComponent {
    @Input() message: string;
    @Input() loading: boolean;
}

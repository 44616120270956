import { Component, OnInit } from '@angular/core';
// import { Stripe, StripePlugin } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import {
    ApiService,
    StripePaymentMethod,
} from '@paystory/services/api.service';
import { LoadingController } from '@ionic/angular';
import { Stripe, StripePlugin } from '@capacitor-community/stripe';

@Component({
    selector: 'app-profile-paymethods',
    templateUrl: './profile-paymethods.page.html',
    styleUrls: ['./profile-paymethods.page.scss'],
})
export class ProfilePaymethodsPage implements OnInit {
    isApplePayAvailable = Capacitor.getPlatform() == 'ios';
    isGooglePayAvailable = Capacitor.getPlatform() == 'android';
    isPayPalAvailable =
        Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('PayPal');
    paymentMethods: StripePaymentMethod[];

    constructor(
        private api: ApiService,
        private loadingCtrl: LoadingController
    ) {}

    async ngOnInit() {
        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        await loading.present();
        try {
            await this.api
                .getMyPaymentMethods()
                .then(paymentMethods => (this.paymentMethods = paymentMethods));
        } catch (_) {}

        try {
            /*(Stripe as StripePlugin)
        .isApplePayAvailable()
        .then(
          (isApplePayAvailable) =>
            (this.isApplePayAvailable = isApplePayAvailable.available)
        );
      (Stripe as StripePlugin)
        .isGooglePayAvailable()
        .then(
          (isGooglePayAvailable) =>
            (this.isGooglePayAvailable = isGooglePayAvailable.available)
        );*/
        } catch (_) {}

        await loading.dismiss();
    }

    async deletePaymentMethod(id: string) {
        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        await loading.present();
        try {
            if (await this.api.deleteMyPaymentMethod(id)) {
                this.paymentMethods = await this.api.getMyPaymentMethods();
            }
        } catch (e) {
            // @ToDo: Show error message that card can't be deleted
        }
        await loading.dismiss();
    }
}

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Position } from '@capacitor/geolocation';
import { LoadingController } from '@ionic/angular';
import { Page, Place } from '@paystory/models';
import { CacheService } from 'ionic-cache';
import { lastValueFrom } from 'rxjs';
import { DeviceService } from './device.service';
import { get, set } from './storage.service';
import { PlaceAvailability } from '@paystory/models/place-availability.interface';

@Injectable({
    providedIn: 'root',
})
export class PlaceService {
    activePlace: Place;
    placeId: string;
    places: Page<Place>;
    placesUpdated = new EventEmitter<void>();

    constructor(
        private http: HttpClient,
        private loadingCtrl: LoadingController,
        private cache: CacheService
    ) {}

    async loadPlace(id?: string) {
        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        await loading.present();

        try {
            const place = await this.get(id || this.placeId);
            console.log(place);
            this.activePlace = place;
        } catch (error) {
        } finally {
            await loading.dismiss();
        }
    }

    async loadPlaces(
        page: number = 1,
        search = null,
        position: Position = null,
        allowedType: string = null
    ): Promise<Page<Place>> {
        const params: any = { page };
        if (position?.coords) {
            params.latitude = position.coords.latitude;
            params.longitude = position.coords.longitude;
        }
        if (search) {
            params.search = search;
        }
        if (allowedType) {
            params.types = [allowedType];
        }
        const data = await this.list(params);
        if (page > 1) {
            if (data.items.length > 0) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < data.items.length; i++) {
                    this.places.items.push(data.items[i]);
                }
            }
            this.places.itemsPerPage = data.itemsPerPage;
            this.places.currentPage = data.currentPage;
            this.places.pageCount = data.pageCount;
            this.places.totalItemCount = data.totalItemCount;
        } else {
            this.places = data;
        }
        this.placesUpdated.next();
        return data;
    }

    async list(params?) {
        return await this.http
            .get<Page<Place>>(
                `${DeviceService.getEnvironmentParameter('api')}/api/places`,
                params ? { params } : undefined
            )
            .toPromise();
    }

    async get(id) {
        const url = `${DeviceService.getEnvironmentParameter(
            'api'
        )}/api/places/${id}`;
        const cacheKey = url;
        const request = this.http.get(url);

        return lastValueFrom(this.cache.loadFromObservable(cacheKey, request));
    }

    async create(data) {
        return await this.http
            .post(
                `${DeviceService.getEnvironmentParameter('api')}/api/places`,
                data
            )
            .toPromise();
    }

    async delete(id) {
        return await this.http
            .delete(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/places/${id}`
            )
            .toPromise();
    }

    async update(id, data) {
        return await this.http
            .post(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/places/${id}`,
                data
            )
            .toPromise();
    }

    async availability(id: string): Promise<PlaceAvailability> {
        return lastValueFrom(
            this.http.get<PlaceAvailability>(
                `${DeviceService.getEnvironmentParameter(
                    'api'
                )}/api/places/${id}/availability`
            )
        );
    }

    setActive(place: Place) {
        this.activePlace = place;
    }

    public async getSelectedOrderType(): Promise<
        'order' | 'delivery' | 'pickup'
    > {
        return (await get('selected-order-type')) || 'order';
    }

    public async setSelectedOrderType(value: 'order' | 'delivery' | 'pickup') {
        await set('selected-order-type', value);
    }
}

import { NavController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Order } from '@paystory/models';

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {
    @Input() order: Order;
    @Input() button = true;

    constructor(private navCtrl: NavController) {}

    ngOnInit() {}

    async orderDetail() {
        await this.navCtrl.navigateForward(['order-detail', this.order.id]);
    }

    getPayMethod(paymentMethod) {
        if (!paymentMethod.startsWith('pm_')) {
            return 'paymentMethod.' + paymentMethod;
        }
    }
}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-title>{{ 'confirm_address' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close"> </ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div
        class="p-5"
        *ngIf="address">
        <div class="grid gap-3">
            <input
                class="input input-bordered input-md w-full"
                [(ngModel)]="address.name"
                [class.input-error]="name.invalid"
                required
                type="text"
                name="name"
                #name="ngModel"
                placeholder="Name (Klingel)" />
            <input
                class="input input-bordered input-xs w-full"
                [class.input-error]="addressField.invalid"
                [(ngModel)]="address.address"
                required
                name="address"
                #addressField="ngModel"
                placeholder="Adresse" />
            <div class="input-group input-group-sm">
                <input
                    class="input input-bordered input-xs w-full"
                    [class.input-error]="zipCode.invalid"
                    [(ngModel)]="address.zipCode"
                    required
                    name="zipCode"
                    #zipCode="ngModel"
                    type="number"
                    placeholder="PLZ" />
                <input
                    class="input input-bordered input-xs w-full"
                    [class.input-error]="city.invalid"
                    [(ngModel)]="address.city"
                    required
                    name="city"
                    #city="ngModel"
                    placeholder="Ort" />
            </div>
            <input
                class="input input-bordered input-xs w-full"
                [(ngModel)]="address.phoneNumber"
                required
                placeholder="Telefonnummer" />
            <textarea
                class="textarea textarea-bordered textarea-xs w-full"
                [(ngModel)]="address.deliveryNotes"
                required
                placeholder="Hinweise für die Lieferung"></textarea>
            <ion-item>
                <ion-label>Ist dies deine Standard Adresse?</ion-label>
                <ion-checkbox
                    slot="end"
                    [(ngModel)]="address.default"
                    [disabled]="forceDefault"></ion-checkbox>
            </ion-item>
        </div>

        <button
            class="btn btn-block mt-5"
            [disabled]="
      name.invalid ||
      addressField.invalid ||
      city.invalid ||
      zipCode.invalid"
            (click)="create()">
            {{ 'Save address' | translate }}
        </button>
    </div>
</ion-content>

import { LoadingItemModule } from '@paystory/components/loading-item/loading-item.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemModule } from '@paystory/components/product-item/product-item.module';
import { OrderCartProductOptionGroupComponentModule } from './order-cart-product-option-group/order-cart-product-option-group.module';
import { OrderCartPageRoutingModule } from './order-cart-routing.module';
import { OrderCartPage } from './order-cart.page';
import { TipModule } from '@paystory/components/tip/tip.module';
import { PaymentMethodModule } from '@paystory/components/payment-method/payment-method.module';
import { CodeInputModule } from '@paystory/components/code-input/code-input.module';

@NgModule({
    imports: [
        TipModule,
        ProductItemModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        OrderCartPageRoutingModule,
        LoadingItemModule,
        OrderCartProductOptionGroupComponentModule,
        CodeInputModule,
        PaymentMethodModule,
    ],
    declarations: [OrderCartPage],
})
export class OrderCartPageModule {}

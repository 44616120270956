import { UpDownModule } from './../up-down/up-down.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { OrderCartProductOptionGroupComponentModule } from 'src/pages/order/pages/order-cart/order-cart-product-option-group/order-cart-product-option-group.module';
import { ProductItemComponent } from './product-item.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DistancePipeModule,
        UpDownModule,
        OrderCartProductOptionGroupComponentModule,
    ],
    declarations: [ProductItemComponent],
    exports: [ProductItemComponent],
})
export class ProductItemModule {}

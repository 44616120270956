<ion-item
    *ngIf="order"
    detail="false"
    mode="md"
    lines="none"
    [button]="button"
    (click)="orderDetail()"
    class="item-border bg-card border-green rounded-md overflow-visible">
    <div class="item_inner">
        <div class="item_info d-flex">
            <div
                [style.backgroundImage]="
                    'url(' + order.place?.picture_600x600 + ')'
                "
                class="h-14 w-14 absolute -left-2 -top-2 rounded-lg bg-cover bg-no-repeat bg-center shadow-md left-image"></div>

            <div class="ml-12 item_details">
                <div class="mb-0 font-bold">
                    {{ order.place?.name }}
                </div>

                <div class="text-xs flex justify-between items-center">
                    <div>
                        <div>
                            {{
                                order.checkoutCompletedAt
                                    | date : 'dd.MM. HH:mm'
                            }}
                            Uhr
                        </div>
                        <div>
                            {{
                                (order.total || 0) +
                                    (order.tip || 0) +
                                    (order.fee || 0) | currency : 'EUR'
                            }}
                            <ng-container
                                *ngIf="getPayMethod(order.paymentMethod)"
                                >|
                                {{
                                    getPayMethod(order.paymentMethod)
                                        | translate
                                }}</ng-container
                            >
                        </div>
                    </div>
                    <div
                        *ngIf="
                            order.state !== 'paid' ||
                            order.paymentMethod !== 'cash'
                        "
                        class="badge"
                        [class.badge-warning]="order.state === 'new'"
                        [class.badge-error]="order.state === 'cancelled'"
                        [class.badge-primary]="order.state === 'paid'"
                        [class.badge-success]="order.state === 'accepted'">
                        {{ 'orderState.' + order.state | translate }}
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="d-flex order_info">
      <div class="spesh_bar"></div>
      <div class="items">
        <ng-container
          *ngFor="let orderItem of order.orderItems"
          [ngTemplateOutlet]="recursiveProducts"
          [ngTemplateOutletContext]="{ productOrOption: orderItem }"
        >
          <h5>
            {{ orderItem.name }} &nbsp;<span>x{{ orderItem.quantity }}</span>
          </h5>
        </ng-container>
      </div>
    </div>-->
    </div>
</ion-item>

<ng-template
    #recursiveProducts
    let-productOrOption="productOrOption">
    <div
        *ngFor="
            let productOptionGroup of productOrOption.orderItemOptionGroups
        ">
        <div *ngFor="let productOption of productOptionGroup.orderItemOptions">
            <h5>
                {{ productOption.title }} &nbsp;<span
                    *ngIf="productOption.quantity"
                    >{{ productOption.quantity }}x</span
                >
            </h5>
            <ng-container
                [ngTemplateOutlet]="recursiveProducts"
                [ngTemplateOutletContext]="{
                    productOrOption: productOption
                }"></ng-container>
        </div>
    </div>
</ng-template>

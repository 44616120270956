import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DistancePipeModule } from './../../../../@paystory/pipes/distance/distance.pipe.module';
import { PlaceHeaderComponent } from './place-header.component';

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        IonicModule,
        DistancePipeModule,
        TranslateModule,
        LazyLoadImageModule,
    ],
    declarations: [PlaceHeaderComponent],
    exports: [PlaceHeaderComponent],
})
export class PlaceHeaderModule {}

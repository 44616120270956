<div class="py-3">
    <div
        class="place-item flex flex-col w-full ion-activatable"
        (click)="openPlace(place)">
        <div>
            <div
                class="bg-image overflow-hidden"
                [lazyLoad]="place?.cover_1300x600"
                [defaultImage]="'assets/images/image-preloader.svg'">
                <div class="badges">
                    <div class="inline-flex -mt-1 gap-2">
                        <div
                            [class.badge-success]="place?.hasOpen"
                            [class.badge-error]="!place?.hasOpen"
                            class="badge">
                            <span class="leading-relaxed whitespace-no-wrap">{{
                                place?.hasOpen ? 'Geöffnet' : 'Geschlossen'
                            }}</span>
                        </div>
                        <div
                            *ngIf="place?.distance"
                            class="badge">
                            <span
                                class="pr-2px leading-relaxed whitespace-no-wrap"
                                >{{ place?.distance | distance }}</span
                            >
                        </div>
                    </div>

                    <div
                        class="type-badge"
                        *ngIf="place?.type === 'restaurant'">
                        <ion-icon name="restaurant-outline"></ion-icon>
                    </div>
                </div>

                <div class="bar gap-4 p-5 pt-8">
                    <div
                        class="place-image"
                        [lazyLoad]="place?.picture_600x600"
                        [defaultImage]="
                            'assets/images/image-preloader.svg'
                        "></div>
                    <div class="info flex flex-col items-start">
                        <div
                            class="text-base font-semibold leading-tight text-white">
                            {{ place?.name }}
                        </div>
                        <div class="text-xs text-center text-white opacity-60">
                            {{ place?.mainTag?.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ion-ripple-effect></ion-ripple-effect>
    </div>
</div>

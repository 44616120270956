import { Place } from '@paystory/models/place.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-place-header',
    templateUrl: './place-header.component.html',
    styleUrls: ['./place-header.component.scss'],
})
export class PlaceHeaderComponent implements OnInit {
    @Input() place: Place;
    constructor() {}

    ngOnInit() {}
}

<ion-item
    lines="none"
    class="bg-card rounded overflow-visible">
    <div
        [style.backgroundImage]="
            'url(' + orderItem?.product?.picture_600x600 + ')'
        "
        class="h-16 w-16 absolute -left-2 -top-2 rounded-lg bg-cover bg-no-repeat bg-center shadow-md left-image"></div>

    <div class="flex flex-col w-full ml-20 py-2 rounded-lg pr-2">
        <ion-label>
            <h2 class="font-semibold">{{ orderItem?.name }}</h2>
            <p>
                {{ orderItem?.unitPrice | currency: 'EUR' }}
            </p>
        </ion-label>
        <app-order-cart-product-option-group
            class="mt-1"
            [item]="orderItem"></app-order-cart-product-option-group>
    </div>
</ion-item>

<div class="flex w-full mb-2 mt-1 justify-end">
    <ion-button
        color="danger"
        fill="clear"
        class="m-0 mr-2 mt-1"
        size="small"
        (click)="deleteProduct()">
        <ion-icon name="trash-outline"></ion-icon>
    </ion-button>
    <app-up-down
        [(ngModel)]="orderItem.quantity"
        (ngModelChange)="amountChange(orderItem, $event)"></app-up-down>
</div>

<ion-header translucent="true">
    <ion-toolbar color="paystory-dark">
        <ion-title>Impressum</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button> </ion-buttons
    ></ion-toolbar>
</ion-header>

<ion-content class="bg_color"
    ><ion-card class="p-5">{{ imprint }}</ion-card></ion-content
>

import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Order } from '@paystory/models';
import { DeviceService } from '@paystory/services/device.service';
import { AnimationOptions } from 'ngx-lottie';
import {
    BehaviorSubject,
    lastValueFrom,
    Observable,
    takeWhile,
    timer,
} from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-order-placed',
    templateUrl: './order-placed.page.html',
    styleUrls: ['./order-placed.page.scss'],
})
export class OrderPlacedPage implements OnInit, AfterViewInit {
    @ViewChild(SwiperComponent) swiper?: SwiperComponent;

    public swiperConf: SwiperOptions = {};

    loadingAnimation: AnimationOptions = {
        path: '/assets/animations/lf30_editor_x0w0hzak.json',
        loop: true,
    };
    paidAnimation: AnimationOptions = {
        path: '/assets/animations/17725-confirmation.json',
        loop: false,
    };
    printAnimation: AnimationOptions = {
        path: '/assets/animations/print.json',
        loop: false,
    };
    orderId: string;
    placeId: string;
    order?: Order;

    public isPaid: Observable<boolean>;
    private isPaidSubject: BehaviorSubject<boolean>;
    public isPrinted: Observable<boolean>;
    private isPrintedSubject: BehaviorSubject<boolean>;

    index: number;

    constructor(
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private http: HttpClient,
        private cdr: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.isPaidSubject = new BehaviorSubject<boolean>(false);
        this.isPaid = this.isPaidSubject.asObservable();

        this.isPrintedSubject = new BehaviorSubject<boolean>(false);
        this.isPrinted = this.isPrintedSubject.asObservable();

        this.orderId = this.route.snapshot.params.orderId;
        this.placeId = this.route.snapshot.queryParams.placeId;
        if (this.orderId) {
            timer(0, 2_000)
                .pipe(
                    untilDestroyed(this),
                    takeWhile(
                        () =>
                            !this.isPaidSubject.value ||
                            !this.isPrintedSubject.value
                    )
                )
                .subscribe(async () => {
                    this.order = await lastValueFrom(
                        this.http.get<Order>(
                            `${DeviceService.getEnvironmentParameter(
                                'api'
                            )}/api/orders/${this.orderId}`
                        )
                    );
                    const isPaid =
                        this.order?.state &&
                        !this.order?.state?.match(/^(cart|new)$/);
                    this.isPaidSubject.next(isPaid);
                    if (isPaid) {
                        await this.swiper?.swiperRef.slideTo(1);
                    }

                    const isPrinted = this.order?.printed;
                    this.isPrintedSubject.next(isPrinted);
                    if (isPrinted) {
                        await this.swiper?.swiperRef.slideTo(2);
                    }
                });
        }
    }
    ngAfterViewInit() {
        /*this.swiperConf.noSwiping = true;
    this.swiperConf.allowTouchMove = false;
    this.swiperConf.allowSlideNext = false;
    this.swiperConf.allowSlidePrev = false;
    this.swiperConf.preventInteractionOnTransition = true;
    this.swiper.updateSwiper(this.swiperConf);*/
    }

    indexChange($event) {
        this.cdr.detectChanges();
    }

    async goToOrders() {
        await this.navCtrl.navigateRoot('/tabs/orders');
    }

    async goToPlace() {
        await this.navCtrl.navigateRoot(`/place/${this.placeId}`);
    }
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-up-down',
    templateUrl: './up-down.component.html',
    styleUrls: ['./up-down.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UpDownComponent),
            multi: true,
        },
    ],
})
export class UpDownComponent implements OnInit {
    value;

    constructor() {}

    ngOnInit() {}

    /** NgModel Start */
    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {}

    /** NgModel End */
    onChange: any = () => {};

    change() {
        this.onChange(this.value);
    }

    modelChange(data) {
        if (!data) {
            this.value = null;
        }
        this.change();
    }

    step(method: string) {
        if (!this.value) {
            this.value = 0;
        }
        if (method == 'up') {
            this.value++;
        }
        if (method == 'down' && this.value > 0) {
            this.value--;
        }

        this.change();
    }
}

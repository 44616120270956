import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-tip',
    templateUrl: './tip.component.html',
    styleUrls: ['./tip.component.scss'],
})
export class TipComponent implements OnInit, OnChanges {
    @Input() total: number;
    @Input() tipAmount: number;
    @Output() tipChange = new EventEmitter<number>();
    selectedTip;
    items = [{ amount: 10 }, { amount: 15 }, { amount: 20 }];

    ngOnInit(): void {
        if (this.tipAmount > 0 && this.total > 0) {
            const tipPercent = Math.floor((this.tipAmount / this.total) * 100);
            this.selectedTip =
                this.items.find(i => i.amount == tipPercent) ||
                this.items.find(i => i.amount == tipPercent + 1) ||
                this.items.find(i => i.amount == tipPercent - 1);
        }
    }

    activate(item) {
        this.selectedTip = item;
        this.change();
    }

    noTip() {
        this.selectedTip = null;
        this.change();
    }

    change() {
        if (this.selectedTip) {
            this.tipAmount = this.total * (this.selectedTip.amount / 100);
        } else {
            this.tipAmount = 0;
        }
        this.tipChange.emit(this.tipAmount);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.total && !changes.total?.firstChange) {
            this.change();
        }
    }
}

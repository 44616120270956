<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-title>Registrieren</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form
        class="pt-5 px-2"
        [formGroup]="registerForm"
        autocomplete="off">
        <ion-list lines="none">
            <ion-item
                lines="none"
                class="animate__animated animate__fadeInUp"
                formGroupName="address">
                <ion-icon
                    class="mt-5"
                    slot="start"
                    name="person-outline"></ion-icon>
                <ion-label position="stacked">Name</ion-label>
                <ion-input
                    formControlName="name"
                    type="name"
                    placeholder="John Doe"></ion-input>
            </ion-item>

            <ion-item
                lines="none"
                class="animate__animated animate__fadeInUp">
                <ion-icon
                    class="mt-5"
                    slot="start"
                    name="mail-outline"></ion-icon>
                <ion-label position="stacked">E-Mail Adresse</ion-label>
                <ion-input
                    formControlName="email"
                    type="email"
                    placeholder="johndoe@mail.com"></ion-input>
            </ion-item>

            <!--<div class="validation-errors">
        <div class="error-message" *ngFor="let error of errors?.email">
          {{ error | translate }}
        </div>
      </div>-->

            <ion-item
                lines="none"
                class="animate__animated animate__fadeInUp">
                <ion-icon
                    class="mt-5"
                    slot="start"
                    name="call-outline"></ion-icon>
                <ion-label position="stacked">Telefonnummer</ion-label>
                <ion-input
                    formControlName="phoneNumber"
                    type="tel"
                    placeholder="+49 151 000000"></ion-input>
            </ion-item>
        </ion-list>
    </form>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-button
            size="medium"
            expand="full"
            class="m-0"
            (click)="register()"
            >Fortsetzen</ion-button
        >
    </ion-toolbar>
</ion-footer>

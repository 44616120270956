import { NavController } from '@ionic/angular';
import { PlaceService } from '@paystory/services/place.service';
import { Component, Input, OnInit } from '@angular/core';
import { Place } from '@paystory/models';

@Component({
    selector: 'place-item',
    templateUrl: './place-item.component.html',
    styleUrls: ['./place-item.component.scss'],
})
export class PlaceItemComponent implements OnInit {
    @Input() place: Place;

    constructor(
        private placeService: PlaceService,
        private navCtrl: NavController
    ) {}

    ngOnInit() {}

    async openPlace(place: Place) {
        this.placeService.setActive(place);
        await this.navCtrl.navigateForward('place/' + place.id);
    }
}

/* eslint-disable @typescript-eslint/no-unused-expressions */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DeviceService } from '@paystory/services/device.service';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/capacitor';
import { init as sentryAngularInit } from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@stripe-elements/stripe-elements/loader';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
    Sentry.init(
        {
            dsn: 'https://539ce4c43afe48ea9d0e788fa837c439@sentry.d-p-it.de/2',
            release: 'paystory-customer-app@##version-tag##',
        },
        sentryAngularInit
    );
    enableProdMode();
    if (window) {
        window.console.log = () => {};
        window.console.warn = () => {};
        window.console.info = () => {};
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
        Capacitor.isNativePlatform() || defineCustomElements(window);
    })
    .catch(err => console.log(err));

<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Bezahlung</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-button (click)="pay()">Bezahlen</ion-button>
</ion-content>

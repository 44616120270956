/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { get, set, remove } from './storage.service';
import { environment } from '../../environments/environment';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    static DEVELOPER_MODE_KEY = 'PayStory-Customer-Developer-Mode';

    static initialized = new Promise(resolve => {
        get(DeviceService.DEVELOPER_MODE_KEY).then(mode => {
            console.log('mode', mode);
            DeviceService.developerMode = mode;
            resolve(mode);
        });
    });

    static developerMode = false;
    unlockDeveloperMode = 5;

    constructor() {}

    async enableDeveloperMode() {
        --this.unlockDeveloperMode;
        if (this.unlockDeveloperMode == 0) {
            const pin = await prompt('Pin');
            if (pin == '3313') {
                DeviceService.developerMode = !DeviceService.developerMode;
                console.log(
                    'DeviceService.developerMode',
                    DeviceService.developerMode
                );
                if (DeviceService.developerMode) {
                    await set(
                        DeviceService.DEVELOPER_MODE_KEY,
                        DeviceService.developerMode
                    );

                    setTimeout(() => location.reload(), 5000);
                    try {
                        await SecureStoragePlugin.clear();
                    } catch (_) {}
                } else {
                    await this.disableDeveloperMode();
                }
                location.reload();
            }
        }

        setTimeout(() => {
            this.unlockDeveloperMode = 5;
        }, 10 * 1000);
    }

    async disableDeveloperMode() {
        DeviceService.developerMode = false;
        await remove(DeviceService.DEVELOPER_MODE_KEY);
        this.unlockDeveloperMode = 5;
        location.reload();
    }

    static getEnvironmentParameter(key: string) {
        return environment[DeviceService.developerMode ? 'dev' : 'prod'][key];
    }
}

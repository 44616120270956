import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PlaceItemModule } from '@paystory/components';
import { LoadingItemModule } from '@paystory/components/loading-item/loading-item.module';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        HomePageRoutingModule,
        DistancePipeModule,
        PlaceItemModule,
        LoadingItemModule,
        LazyLoadImageModule,
        GoogleMapsModule,
    ],
    declarations: [HomePage],
})
export class HomePageModule {}

import { HelperService } from '@paystory/services/helper.service';
import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { User } from '@paystory/models';
import { ApiService } from '@paystory/services/api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-profile',
    templateUrl: './profile.page.html',
    styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
    user: User;
    constructor(
        private navCtrl: NavController,
        public apiService: ApiService,
        private helper: HelperService
    ) {}

    async ngOnInit() {
        this.user = this.apiService.currentUserValue;
        this.apiService.currentUser
            .pipe(untilDestroyed(this))
            .subscribe(user => (this.user = user));
    }

    termsConditions() {}

    async logout() {
        await this.apiService.logout();
        this.navCtrl.navigateRoot('login');
    }

    paymethods() {
        this.navCtrl.navigateForward('profile-paymethods');
    }
    support() {
        this.helper.openLink('https://paystory.de/kontakt/');
        //  this.navCtrl.navigateForward('support');
    }
    savedAddresses() {
        this.navCtrl.navigateForward('saved-addresses');
    }
    feedback() {
        this.helper.openLink('https://paystory.de/feedback-kunde/');
    }
    favorites() {
        this.navCtrl.navigateForward('favorites');
    }
    settings() {
        this.navCtrl.navigateForward('settings');
    }
    legal() {
        this.navCtrl.navigateForward('legal');
    }
}

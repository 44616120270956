<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Einstellungen</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list class="mt-5">
        <ion-item lines="none">
            <ion-label> Dunkelmodus </ion-label>
            <ion-toggle
                slot="end"
                [(ngModel)]="helper.darkModeEnabled"
                (ngModelChange)="helper.setDarkMode($event)">
            </ion-toggle> </ion-item
    ></ion-list>
</ion-content>

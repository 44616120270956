import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { PlaceItemComponent } from './place-item.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DistancePipeModule,
        LazyLoadImageModule,
    ],
    declarations: [PlaceItemComponent],
    exports: [PlaceItemComponent],
})
export class PlaceItemModule {}

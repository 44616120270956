/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { DeviceService } from '../device.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);

    constructor(private apiService: ApiService, private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Change API URL
        if (request.url.startsWith('api')) {
            const requestUrl = request.url.replace('api/', '');
            const api = `${DeviceService.getEnvironmentParameter(
                'api'
            )}/api/${requestUrl}`;
            request = request.clone({ url: api });
        } else {
            request = request.clone({ url: request.url });
        }

        // Add Auth Token
        const currentUser = this.apiService.currentUserValue;
        if (currentUser && currentUser.access_token) {
            request = this.addToken(request);
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (
                        (error.error.error === 'invalid_grant' &&
                            (error.error.error_description.match(/invalid/i) ||
                                error.error.error_description.match(
                                    /refresh.+token.+expire/i
                                ))) ||
                        (error.error.error === 'access_denied' &&
                            (error.error.error_description.match(
                                /account.+disabled/i
                            ) ||
                                error.error.error_description.match(
                                    /auth.+required/i
                                ))) ||
                        error.error.logout
                    ) {
                        this.apiService.logout();
                        this.router.navigate(['/login']);
                        return throwError('Not logged in and cant refresh!');
                    } else if (
                        error.error.error === 'invalid_grant' &&
                        error.error.error_description.match(
                            /access.+token.+expire/i
                        )
                    ) {
                        return this.handleError(request, next);
                    }
                }
                return throwError(error);
            })
        );
    }

    private addToken(request: HttpRequest<any>) {
        const currentUser = this.apiService.currentUserValue;
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${currentUser.access_token}`,
            },
        });
    }

    private handleError(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.apiService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token);
                    return next.handle(this.addToken(request));
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(() => {
                    return next.handle(this.addToken(request));
                })
            );
        }
    }
}

import { PlaceInfoPageModule } from './pages/place-info/place-info.module';
import { PlaceProductsPageModule } from './pages/place-products/place-products.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PlacePage } from './place.page';

const routes: Routes = [
    {
        path: '',
        component: PlacePage,
        children: [
            {
                path: '',
                redirectTo: 'products',
                pathMatch: 'full',
            },
            {
                path: 'products',
                loadChildren: () => PlaceProductsPageModule,
            },
            {
                path: 'info',
                loadChildren: () => PlaceInfoPageModule,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PlacePageRoutingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PaymentMethodComponent } from './payment-method.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
    declarations: [PaymentMethodComponent],
    exports: [PaymentMethodComponent],
})
export class PaymentMethodModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';

import { SetLocationPageRoutingModule } from './set-location-routing.module';

import { SetLocationPage } from './set-location.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SetLocationPageRoutingModule,
        GoogleMapsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    declarations: [SetLocationPage],
})
export class SetLocationPageModule {}

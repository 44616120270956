import { ActivatedRoute } from '@angular/router';
import { PlaceService } from '@paystory/services/place.service';
import { Component, Input, OnInit } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Place } from '@paystory/models';
import Swal from 'sweetalert2';

@Component({
    template: `
        <ion-item
            *ngIf="place.phoneNumber"
            button
            (click)="call()"
            lines="none">
            <ion-icon
                name="call-outline"
                slot="start"></ion-icon>
            <ion-label>Anrufen</ion-label>
        </ion-item>
    `,
})
export class PlacePopoverPage {
    @Input() place: Place;
    constructor(private callNumber: CallNumber) {}

    async call() {
        await this.callNumber.callNumber(this.place.phoneNumber, true);
    }
}

@Component({
    selector: 'app-place',
    templateUrl: './place.page.html',
    styleUrls: ['./place.page.scss'],
})
export class PlacePage implements OnInit {
    constructor(
        private placeService: PlaceService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.placeService.placeId = this.route.snapshot.params?.id;

        this.placeService
            .availability(this.placeService.placeId)
            .then(availability => {
                if (!availability.available) {
                    Swal.fire({
                        title: 'Nicht verfügbar',
                        text: `Wir konnten nicht mit dem Restaurant kommunizieren, daher können wir derzeit keine Bestellungen etc. vornehmen.
            Du kannst dem Restaurant dies gerne mitteilen - wir kümmern uns aber ebenfalls schnellstmöglich um eine Problemlösung.
            Vielen Dank für dein Verständnis.`,
                        icon: 'info',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        showCloseButton: false,
                        backdrop: true,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }
            });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { OrderItem, OrderItemOption } from '@paystory/models';
@Component({
    selector: 'app-order-cart-product-option-group',
    templateUrl: './order-cart-product-option-group.component.html',
    styleUrls: ['./order-cart-product-option-group.component.scss'],
})
export class OrderCartProductOptionGroupComponent implements OnInit {
    @Input() item: OrderItem | OrderItemOption;
    selectedProductOptions: OrderItem[] | OrderItemOption[];

    constructor() {}

    ngOnInit() {
        this.updateSelectedProductOptions();
    }

    updateSelectedProductOptions() {
        this.selectedProductOptions = [];
        this.item.orderItemOptionGroups.forEach(pog => {
            pog.orderItemOptions.forEach(po => {
                this.selectedProductOptions.push(po);
            });
        });
    }
}

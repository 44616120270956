<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Bestätigen</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="p-5">
        <div
            class="box flex flex-col shadow-xl rounded-xl mt-3 overflow-hidden bg-white mx-auto">
            <div
                class="py-6 px-14 bg-gradient-to-tr from-gray-900 to-gray-700 text-center -mb-5 pb-11">
                <h2 class="text-white text-xs">
                    Du solltest einen Code per SMS erhalten haben.
                </h2>
                <h4 class="text-white text-center font-bold text-xl mt-2">
                    SMS Code
                </h4>
            </div>
            <div class="flex flex-col py-6 px-8 space-y-5 bg-white rounded-xl">
                <div class="flex items-center justify-center">
                    <code-input
                        [isCodeHidden]="false"
                        [codeLength]="5"
                        (codeChanged)="onCodeChanged($event)"
                        (codeCompleted)="onCodeCompleted($event)">
                    </code-input>
                    <!-- <ion-item lines="none">
            <ion-label
              class="
                font-semibold
                text-xs
                uppercase
                tracking-wider
                leading-none
                mb-2
                mt-2
              "
              position="stacked"
              >Bestätigungscode eingeben</ion-label
            >
            <ion-input
              class="border-b-2 border-gray-600"
              type="number"
              placeholder="55 555"
              autocomplete="one-time-code"
              [(ngModel)]="confirmationCode"
              (ngModelChange)="confirmationCode?.toString().length === 5 ? goToRegister() : ''"
            ></ion-input>
          </ion-item>-->
                </div>
            </div>
        </div>
    </div>

    <!-- @ToDo: Fehler bei ungültigem Code! -->
    <ul
        class="px-4"
        *ngIf="errors?.confirmationCode">
        <li
            class="text-red-500 font-semibold text-xs uppercase tracking-wider leading-none mb-2 mt-2"
            *ngFor="let error of errors?.confirmationCode">
            {{ error }}
        </li>
    </ul>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <div class="flex items-center justify-between p-3">
            <div class="font-bold">{{ timer }} &nbsp;Sek.</div>
            <ion-button
                size="small"
                [disabled]="!(timer == 0) && !resendLoading"
                (click)="resend()"
                >Erneut senden</ion-button
            >
        </div>
    </ion-toolbar>
</ion-footer>

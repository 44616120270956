<ion-item
    class="loading-item"
    lines="none">
    <div
        *ngIf="message && !loading"
        class="no-items font-semibold text-left text-xs  uppercase tracking-wider leading-none">
        <span>{{ message }}</span>
    </div>
    <ion-avatar class="ml-1 mr-10">
        <ion-skeleton-text [animated]="loading"></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
        <h3>
            <ion-skeleton-text
                [animated]="loading"
                style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
            <ion-skeleton-text
                [animated]="loading"
                style="width: 80%"></ion-skeleton-text>
        </p>
        <p>
            <ion-skeleton-text
                [animated]="loading"
                style="width: 60%"></ion-skeleton-text>
        </p>
    </ion-label>
</ion-item>

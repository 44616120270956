import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'distance',
})
export class DistancePipe implements PipeTransform {
    transform(value: number, ...args: string[]): string {
        const val = parseFloat(value?.toString());
        if (val) {
            if (val < 1) {
                return (
                    (val * 100).toFixed(0) + (args.length >= 1 ? args[0] : ' m')
                );
            } else {
                return val.toFixed(2) + (args.length >= 2 ? args[1] : ' km');
            }
        }
        return null;
    }
}

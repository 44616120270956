<ion-app>
    <div
        class="dev-mode"
        *ngIf="developerMode">
        DEV
    </div>
    <ion-split-pane contentId="main-content">
        <ion-menu
            contentId="main-content"
            type="overlay"
            class="main-menu">
            <ion-content>
                <div class="h-full flex flex-col justify-between">
                    <div class="px-4 py-5">
                        <ion-list>
                            <ion-menu-toggle>
                                <ion-item
                                    auto-hide
                                    [routerLink]="['/tabs/profile']"
                                    class="my-10">
                                    <ion-label>
                                        <h2>
                                            {{
                                                (apiService.currentUser | async)
                                                    ?.displayName
                                            }}
                                        </h2>
                                        <p>
                                            {{
                                                (apiService.currentUser | async)
                                                    ?.mobileNumber
                                            }}
                                        </p>
                                    </ion-label>
                                </ion-item>
                            </ion-menu-toggle>
                            <ion-menu-toggle
                                auto-hide="false"
                                *ngFor="let p of appPages; let i = index">
                                <div
                                    *ngIf="p.divider"
                                    class="block h-5"></div>
                                <ion-item
                                    *ngIf="!p.divider"
                                    routerDirection="root"
                                    [routerLink]="[p.url]"
                                    lines="none"
                                    detail="false"
                                    routerLinkActive="active">
                                    <ion-icon
                                        slot="start"
                                        [name]="p.icon"></ion-icon>
                                    <ion-label>{{ p.title }}</ion-label>
                                </ion-item>
                            </ion-menu-toggle>
                            <ion-menu-toggle auto-hide="false">
                                <ion-item
                                    (click)="support()"
                                    lines="none"
                                    detail="false"
                                    routerLinkActive="active">
                                    <ion-icon
                                        slot="start"
                                        name="information-circle-outline"></ion-icon>
                                    <ion-label>Benötigst du Hilfe?</ion-label>
                                </ion-item>
                            </ion-menu-toggle>
                        </ion-list>
                    </div>
                    <div class="px-3">
                        <app-version [dark]="true"></app-version>
                    </div>
                </div>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>

import { HomePageModule } from 'src/pages/home/home.module';
import { ProfilePageModule } from 'src/pages/user/pages/profile/profile.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { OrderOverviewPageModule } from '../order/pages/order-overview/order-overview.module';

const routes: Routes = [
    {
        path: '',
        component: TabsPage,
        children: [
            {
                path: 'home',
                loadChildren: () => HomePageModule,
            },
            {
                path: 'orders',
                loadChildren: () => OrderOverviewPageModule,
            },
            {
                path: 'profile',
                loadChildren: () => ProfilePageModule,
            },
            {
                path: '',
                redirectTo: '/tabs/home',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TabsPageRoutingModule {}

import { PlaceHeaderModule } from './../../../../components/place-header/place-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlaceInfoPageRoutingModule } from './place-info-routing.module';

import { PlaceInfoPage } from './place-info.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        PlaceInfoPageRoutingModule,
        PlaceHeaderModule,
    ],
    declarations: [PlaceInfoPage],
})
export class PlaceInfoPageModule {}

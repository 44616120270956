export const environment = {
    production: true,
    prod: {
        api: 'https://api.paystory.de',
        apiWss: 'wss://wss.paystory.de',
        oauthClientId: '1_39snr88o0v28c04g08cok4ckgs804go48c0soggc444sccs4go',
        oauthClientSecret: '19iem2qjk1gkw80ckccocgco0g8woswgggkkgg8gcs4ssk4s0o',
    },
    dev: {
        api: 'https://api.dev.paystory.de',
        apiWss: 'wss://wss.dev.paystory.de',
        oauthClientId: '1_yhzg4jkmnw0skokco4wo0ogo44s8cos8oswowc0cgwk0kskc8',
        oauthClientSecret: '3ftce5bfyfmssgcwcksg4ogkw00wow88w8gswkgo08c8ow08k0',
    },
    appName: 'paystory-customer-app',
};

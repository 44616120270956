import { OnDestroy } from '@angular/core';
/* eslint-disable eqeqeq */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    SignInWithApple,
    SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import {
    FacebookCurrentAccessTokenResponse,
    FacebookLogin,
} from '@capacitor-community/facebook-login';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import {
    LoadingController,
    MenuController,
    NavController,
    ToastController,
    ViewWillEnter,
    ViewWillLeave,
} from '@ionic/angular';
import { ApiService } from '@paystory/services/api.service';
import { DeviceService } from '@paystory/services/device.service';
import { HelperService } from '@paystory/services/helper.service';
import { get } from '@paystory/services/storage.service';

declare const FB: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage
    implements OnInit, OnDestroy, ViewWillEnter, ViewWillLeave
{
    phoneNumber: number;
    isNative = Capacitor.isNativePlatform();
    isiOS =
        Capacitor.getPlatform() === 'ios' &&
        Capacitor.isPluginAvailable('SignInWithApple');

    keyboardSize = 0;

    constructor(
        private loadingCtrl: LoadingController,
        private navCtrl: NavController,
        private api: ApiService,
        private helper: HelperService,
        private toastCtrl: ToastController,
        private cdr: ChangeDetectorRef,
        public deviceService: DeviceService,
        private menuController: MenuController
    ) {}

    async ionViewWillEnter() {
        this.menuController.enable(false);
        if (Capacitor.isNativePlatform()) {
            StatusBar.setStyle({
                style: Style.Dark,
            });
            // Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
            /*if (Capacitor.getPlatform() == 'android') {
        await Keyboard.addListener('keyboardWillShow', (e) => {
          this.keyboardSize = e.keyboardHeight;
          this.cdr.detectChanges();
        });
        await Keyboard.addListener('keyboardDidHide', () => {
          this.keyboardSize = 0;
          this.cdr.detectChanges();
        });
      }*/
        }
    }

    ionViewWillLeave() {
        /*if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({
        style: StatusBarStyle.Light,
      });
    }*/
    }

    async ngOnDestroy() {
        // await Keyboard?.removeAllListeners();
    }

    async ngOnInit() {
        if (!this.isNative) {
            //  registerWebPlugin(FacebookLoginWeb);
            const fbInitPromise = new Promise(resolve => {
                (window as any).fbAsyncInit = () => {
                    FB.init({
                        appId: '902343530296497',
                        cookie: true,
                        xfbml: true,
                        version: 'v8.0',
                    });
                };
            });
            await this.helper.loadScript(
                'https://connect.facebook.net/de_DE/sdk.js'
            );
            await fbInitPromise;
        }
    }

    async loginWithPhone() {
        const message = 'Login mit deiner Telefonnummer ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        let phoneNumber;
        if (this.phoneNumber.toString().startsWith('0')) {
            phoneNumber = this.phoneNumber.toString().trim().substring(1);
        } else if (this.phoneNumber.toString().startsWith('+49')) {
            phoneNumber = this.phoneNumber.toString().trim().substring(3);
        } else {
            phoneNumber = this.phoneNumber.toString().trim();
        }

        await loading.present();
        try {
            const mobileNumber = `+49${phoneNumber}`;
            await this.api.registerWithMobileNumber({
                mobileNumber,
            });

            await this.api.addCurrentUserValue({
                mobileNumber,
            });

            this.navCtrl.navigateForward('verification');
        } catch (e) {
            console.log('e', e);
            const toast = await this.toastCtrl.create({
                message: 'Fehler: ' + e,
                translucent: true,
                duration: 5000,
            });
            toast.present();
        } finally {
            await loading.dismiss();
        }
    }

    async login(type: 'facebook' | 'google' | 'apple' | 'phone') {
        let message;
        switch (type) {
            case 'facebook':
                message = 'Login mit Facebook ...';
                break;
            case 'google':
                message = 'Login mit Google ...';
                break;
            case 'apple':
                message = 'Login mit Apple ...';
                break;
        }
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        await loading.present();

        try {
            if (type === 'facebook') {
                let currentAccessTokenResponse: FacebookCurrentAccessTokenResponse;
                try {
                    currentAccessTokenResponse =
                        await FacebookLogin.getCurrentAccessToken();
                } catch (e) {}

                if (!currentAccessTokenResponse?.accessToken) {
                    currentAccessTokenResponse = await FacebookLogin.login({
                        permissions: ['email'],
                    });
                }

                console.log('Got FB response', currentAccessTokenResponse);
                // alert(JSON.stringify(currentAccessTokenResponse));
                await this.api.registerWith(
                    'facebook',
                    currentAccessTokenResponse.accessToken?.token
                );
            } else if (type === 'apple') {
                const response: SignInWithAppleResponse =
                    await SignInWithApple.authorize();
                console.log('Got Apple response', response);
                // alert(JSON.stringify(response));
                await this.api.registerWith(
                    'apple',
                    response.response?.authorizationCode
                );
            }

            await loading.dismiss();
            await this.navCtrl.navigateForward('/social-login');
        } catch (e) {
            await loading.dismiss();
            console.error('Got social login error', e);
            // alert(JSON.stringify(e));
        }
    }
}

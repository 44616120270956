/* eslint-disable @typescript-eslint/naming-convention */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Category, Place, Product } from '@paystory/models';
import { CartService } from '@paystory/services/cart.service';
import { HelperService } from '@paystory/services/helper.service';
import { PlaceService } from '@paystory/services/place.service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { CacheService } from 'ionic-cache';
import { filter } from 'rxjs';
import Swal from 'sweetalert2';
import { PlacePopoverPage } from '../../place.page';

@UntilDestroy()
@Component({
    selector: 'app-place-products',
    templateUrl: './place-products.page.html',
    styleUrls: ['./place-products.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceProductsPage implements OnInit {
    tab = 'burger';
    favoriteIcon = false;
    place: Place;
    loading = false;
    cartTotal$ = this.cartService.getTotal();
    count = 5;

    infoTab: 'products' | 'info' = 'products';

    viewportHeight;

    isRefreshing = false;
    productSelectionActive = false;
    variationSelectionModal;
    currentSelectedProduct;
    cupertino = false;
    myPane: CupertinoPane;

    constructor(
        private navCtrl: NavController,
        public placeService: PlaceService,
        private route: ActivatedRoute,
        public cartService: CartService,
        private popoverCtrl: PopoverController,
        public helper: HelperService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private cache: CacheService
    ) {}

    async presentPopover(ev: any) {
        const popover = await this.popoverCtrl.create({
            component: PlacePopoverPage,
            event: ev,
            translucent: true,
            componentProps: {
                place: this.place,
            },
        });
        return await popover.present();
    }

    myHeaderFn(record, recordIndex, records) {
        if (recordIndex % 20 === 0) {
            return 'Header ' + recordIndex;
        }
        return null;
    }

    async doRefresh($event) {
        if (this.isRefreshing) return;
        this.isRefreshing = true;
        await this.cache.clearAll();
        await this.placeService.loadPlace();
        this.place = this.placeService.activePlace;
        await this.init();
        $event.target.complete();
        this.isRefreshing = false;
    }

    async ngOnInit() {
        this.loading = true;
        await this.placeService.loadPlace();
        this.place = this.placeService.activePlace;

        this.init();
        this.router.events
            .pipe(untilDestroyed(this))
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(async (event: Event) => {
                this.init();
            });
    }

    async init() {
        try {
            this.tab = this.onlyWithProducts(this.place?.categories)[0]?.id;
            const details: any = {};
            const queryParamMap = this.route.snapshot.queryParamMap;
            if (queryParamMap.has('tableNumber')) {
                details.tableNumber = queryParamMap.get('tableNumber');
                details.tableNumberQRCode = true;
            }
            await this.cartService.openPlace(this.place, details);
            for (const k of Object.keys(details)) {
                await this.cartService.setDetail(k, details[k]);
            }
            this.loading = false;
            this.cdr.detectChanges();
        } catch (error) {}
    }

    async cart() {
        try {
            const availability = await this.placeService.availability(
                this.placeService.placeId
            );
            if (!availability.available) {
                Swal.fire({
                    title: 'Nicht verfügbar',
                    text: `Wir konnten nicht mit dem Restaurant kommunizieren, daher können wir derzeit keine Bestellungen etc. vornehmen.
        Du kannst dem Restaurant dies gerne mitteilen - wir kümmern uns aber ebenfalls schnellstmöglich um eine Problemlösung.
        Vielen Dank für dein Verständnis.`,
                    icon: 'info',
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    showCloseButton: false,
                    backdrop: true,
                    timer: 5000,
                    timerProgressBar: true,
                });
                return;
            }
        } catch (error) {}

        this.navCtrl.navigateForward(['order-cart', this.placeService.placeId]);
    }

    toggleFavoriteIcon() {
        this.favoriteIcon = !this.favoriteIcon;
    }

    reviews() {}

    async variationSelection($event, product: Product) {
        if (this.productSelectionActive) return;

        if ($event.target?.tagName?.toLowerCase() === 'span') {
            return;
        }
        this.productSelectionActive = true;
        if (!this.place.hasOpen) {
            Swal.fire({
                title: 'Geschlossen',
                text: 'Wir haben aktuell geschlossen.',
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: false,
                showDenyButton: false,
                showCloseButton: false,
                backdrop: true,
                timer: 3000,
                timerProgressBar: true,
            });
            return;
        }

        this.currentSelectedProduct = JSON.parse(JSON.stringify(product));

        this.cupertino = true;
        const settings: CupertinoSettings = {
            initialBreak: 'top',
            backdrop: true,
            backdropOpacity: 0.4,
            bottomClose: true,
            fastSwipeClose: true,
            simulateTouch: true,

            dragBy: ['.pane .draggable'],
            /*zStack: {
        pushElements: ['app-place'],
        cardYOffset: 30
      },*/
            events: {
                onDidDismiss: e => {
                    this.cupertino = false;
                    this.productSelectionActive = false;
                },
                onBackdropTap: async ev => {
                    await this.myPane?.hide();
                    this.myPane?.destroy();
                },
                /*onDidPresent: ev => {
                    const backdrop = document.querySelector(
                        '.cupertino-pane-wrapper .backdrop'
                    );
                    backdrop.addEventListener('click', async event => {
                        await this.myPane?.hide();
                        this.myPane?.destroy();
                    });
                    console.log('backdrop', backdrop);
                },*/
            },
            parentElement: 'app-place',
        };
        if (this.myPane) {
            this.myPane.destroy();
        }
        this.myPane = new CupertinoPane('.variation-selection', settings);
        this.myPane.present({ animate: true });

        /*this.variationSelectionModal = await this.modalController.create({
      component: VariationSelectionPage,
      componentProps: {
        product: JSON.parse(JSON.stringify(product))
      },
      presentingElement: this.routerOutlet.nativeEl,
      swipeToClose: true,
      mode: 'ios',
      breakpoints: [0, 0.5, 0.8],
      initialBreakpoint: 0.5,
      backdropDismiss: true
    });
    await this.variationSelectionModal.present();
    this.variationSelectionModal.onDidDismiss().then(async (data) => {
      this.productSelectionActive = false;
    });*/
    }

    onlyWithProducts(categories: Category[] | undefined) {
        return categories?.filter(c => c.products?.length);
    }

    trackById = (index: number, item: any) => item?.id;
}

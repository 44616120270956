import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductOptionGroup, ProductOption } from '@paystory/models';

@Component({
    selector: 'app-product-option-group',
    templateUrl: './product-option-group.component.html',
    styleUrls: ['./product-option-group.component.scss'],
})
export class ProductOptionGroupComponent implements OnInit {
    @Input() item: Product | ProductOption;
    selectedProductOptions: Product[] | ProductOption[];

    constructor() {}

    ngOnInit() {
        this.item.productOptionGroups.forEach(pog => {
            pog.productOptions.forEach(po => {
                if (po.isDefault) {
                    po.selected = true;
                }
            });
        });
        this.updateSelectedProductOptions();
    }

    defaultSelect(optionGroup: ProductOptionGroup) {
        return optionGroup.productOptions.find(po => po.isDefault)?.id;
    }

    select($event: any, productOptionGroup: ProductOptionGroup) {
        const selectedId = $event.detail?.value;
        const selectedProductOption = productOptionGroup.productOptions?.find(
            po => po.id === selectedId
        );
        productOptionGroup.productOptions?.forEach(po => (po.selected = false));
        if (selectedProductOption) {
            selectedProductOption.selected = true;
        }
        this.updateSelectedProductOptions();
    }

    updateSelectedProductOptions() {
        this.selectedProductOptions = [];
        this.item.productOptionGroups.forEach(pog => {
            pog.productOptions.forEach(po => {
                if (po.selected) {
                    this.selectedProductOptions.push(po);
                }
            });
        });
    }
}

/* eslint-disable @typescript-eslint/prefer-for-of */
import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Geolocation } from '@capacitor/geolocation';
import { Toast } from '@capacitor/toast';
import {
    ActionSheetController,
    IonRouterOutlet,
    ModalController,
    NavController,
    ViewDidEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@paystory/services/api.service';
import { DeviceService } from '@paystory/services/device.service';
import { HelperService } from '@paystory/services/helper.service';
import { LocationService } from '@paystory/services/location.service';
import { PlaceService } from '@paystory/services/place.service';
import { lastValueFrom } from 'rxjs';
import { ConfirmAddressPage } from '../confirm-address/confirm-address.page';
import { DeliveryAddress } from '../saved-addresses/saved-addresses.page';

@Component({
    selector: 'app-set-location',
    templateUrl: './set-location.page.html',
    styleUrls: ['./set-location.page.scss'],
})
export class SetLocationPage implements OnInit, OnDestroy, ViewDidEnter {
    @ViewChild(GoogleMap) map: GoogleMap;

    loading = false;
    markers: google.maps.Marker[] = [];
    autocomplete: any = {
        input: '',
    };
    googleAutocomplete: google.maps.places.AutocompleteService;
    googlePlaces: google.maps.places.PlacesService;
    geocoder: google.maps.Geocoder;
    autocompleteItems: any = [];
    events: any = [];

    positionChanged = false;
    locationsList: any = [];
    selectedLocation;
    userPosition: { lat: number; lng: number };

    constructor(
        public helper: HelperService,
        public zone: NgZone,
        public locationService: LocationService,
        private actionSheetCtrl: ActionSheetController,
        private apiService: ApiService,
        private cdr: ChangeDetectorRef,
        private placeService: PlaceService,
        private translate: TranslateService,
        private modelCtrl: ModalController,
        private routerOutlet: IonRouterOutlet,
        private http: HttpClient,
        private navCtrl: NavController
    ) {}

    async ngOnInit() {
        this.loading = true;
    }

    saveLocation() {
        this.locationActionSheet();
    }

    async ionViewDidEnter() {
        google.maps.event.addListener(this.map?.googleMap, 'idle', () => {
            google.maps.event.trigger(this.map?.googleMap, 'resize');
        });

        this.map?.googleMap?.addListener('click', async resp => {
            this.clearMarkers();
            const pos = {
                lat: resp.latLng.lat(),
                lng: resp.latLng.lng(),
            };
            this.createMarker(pos, true);
            // await this.updateUserPosition(pos.lat, pos.lng);
        });
        this.geocoder = new google.maps.Geocoder();
        const elem = document.createElement('div');
        this.googlePlaces = new google.maps.places.PlacesService(elem);
        this.googleAutocomplete = new google.maps.places.AutocompleteService();

        const user = this.apiService.currentUserValue;

        if (user.address?.latitude && user.address?.longitude) {
            this.createMarker(
                { lat: user.address?.latitude, lng: user.address?.longitude },
                true
            );
        }
        this.loading = false;
    }

    async ngOnDestroy() {
        // this.api.swipeGesture = true;
        if (this.positionChanged) {
            await this.placeService.loadPlaces();
        }
    }

    async tryGeolocation() {
        this.loading = true;
        let resp;

        if (Geolocation) {
            try {
                resp = await Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                    timeout: 15000,
                });
            } catch (e) {
                console.warn('Geolocation problem 1', e);
                try {
                    resp = await Geolocation.getCurrentPosition({
                        enableHighAccuracy: false,
                        timeout: 5000,
                    });
                } catch (error) {
                    await Toast.show({
                        text: 'Standort konnte nicht ermittelt werden.',
                    });
                    console.warn('Geolocation problem 2', error);
                    return;
                }
            }
        }

        if (resp?.coords?.latitude) {
            try {
                this.clearMarkers();
                const pos = {
                    lat: resp.coords.latitude,
                    lng: resp.coords.longitude,
                };
                this.createMarker(pos, true);

                //await this.updateUserPosition(pos.lat, pos.lng);
                this.loading = false;
            } catch (error) {
            } finally {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        }
    }

    async updateUserPosition(lat, lng) {
        this.loading = true;

        const location = {
            latitude: lat,
            longitude: lng,
        };

        await this.apiService.updateMyself(location);
        this.positionChanged = true;

        await Toast.show({
            text: 'Position gespeichert!',
        });
        this.loading = false;
        this.cdr.detectChanges();
    }

    updateSearchResults() {
        if (this.autocomplete.input == '') {
            this.autocompleteItems = [];
            return;
        }
        this.googleAutocomplete.getPlacePredictions(
            { input: this.autocomplete.input },
            (predictions, status) => {
                this.autocompleteItems = [];
                if (predictions) {
                    this.zone.run(() => {
                        predictions.forEach(prediction => {
                            this.autocompleteItems.push(prediction);
                        });
                    });
                }
            }
        );
    }

    selectSearchResult(item) {
        this.clearMarkers();
        this.autocompleteItems = [];
        this.autocomplete.input = '';

        this.geocoder.geocode(
            { placeId: item.place_id },
            async (results, status) => {
                if (status === google.maps.GeocoderStatus.OK && results[0]) {
                    const position = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                    };
                    this.createMarker(position, true);
                    // await this.updateUserPosition(position.lat, position.lng);
                }
            }
        );
    }

    createMarker(pos, setCenter?) {
        console.log('createMarker', pos);
        this.userPosition = {
            lat: pos.lat,
            lng: pos.lng,
        };

        const marker = new google.maps.Marker({
            position: pos,
            map: this.map.googleMap,
            title: 'Meine Position',
            draggable: true,
        });

        this.markers.push(marker);
        const event = google.maps.event.addListener(
            marker,
            'dragend',
            async ev => {
                const markerPos = marker.getPosition();
                this.userPosition = {
                    lat: markerPos.lat(),
                    lng: markerPos.lng(),
                };
                console.log('markerPos', this.userPosition);
                // await this.updateUserPosition(markerPos.lat(), markerPos.lng());
            }
        );
        this.events.push(event);
        if (setCenter) {
            this.map.googleMap.setZoom(12);
            this.map.googleMap.setCenter(pos);
        }
    }

    clearMarkers() {
        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.events.forEach(event => {
            google.maps.event.removeListener(event);
        });
        this.markers = [];
    }

    async locationActionSheet() {
        console.log('this.userPosition', this.userPosition);

        let address = await this.locationService.getAddressFromLatLng(
            this.userPosition.lat,
            this.userPosition.lng
        );
        address = this.locationService.formatAddressObject(
            address.address_components
        );
        address = {
            ...this.userPosition,
            ...address,
            name: this.apiService.currentUserValue?.address?.name || '',
            phoneNumber:
                this.apiService.currentUserValue?.address?.phoneNumber || '',
        };

        const addressString = `
      ${address.address || ''} ${address.streetNumber || ''}\n
      ${address.zipCode || ''} ${address.city || ''}
    `;

        const buttons = [
            {
                text: await this.translate.instant('new_address'),
                icon: 'add-outline',
                id: 'new-address-btn',
                handler: async () => {
                    const modal = await this.modelCtrl.create({
                        component: ConfirmAddressPage,
                        componentProps: {
                            address,
                        },
                        presentingElement: this.routerOutlet.nativeEl,
                        mode: 'ios',
                    });
                    await modal.present();

                    modal.onDidDismiss().then((data: any) => {
                        if (data?.data?.saved) {
                            this.navCtrl.back();
                        }
                    });
                },
            },
        ];

        const actionSheet = await this.actionSheetCtrl.create({
            header: await this.translate.instant('your_location'),
            subHeader: addressString,
            cssClass: 'set-location-sheet',
            translucent: true,
            buttons,
        });

        await actionSheet.present();
    }
}

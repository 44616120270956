import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { Order, Page } from '@paystory/models';
import { DeviceService } from '@paystory/services/device.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-order-overview',
    templateUrl: './order-overview.page.html',
    styleUrls: ['./order-overview.page.scss'],
})
export class OrderOverviewPage implements ViewWillEnter {
    pageInProgress: Page<Order>;
    pageInProgressLoading = false;
    pageFulfilled: Page<Order>;
    pageFulfilledLoading = false;

    constructor(private http: HttpClient) {}

    async ionViewWillEnter() {
        delete this.pageInProgress;
        this.pageInProgressLoading = false;
        delete this.pageFulfilled;
        this.pageFulfilledLoading = false;
        await this.loadOrders();
    }

    async loadOrders(state?) {
        const params: any = {};
        if (state) {
            params.state = state;
        } else {
            params.state = '!cart';
        }
        this[state ? 'pageFulfilledLoading' : 'pageInProgressLoading'] = true;
        this[state ? 'pageFulfilled' : 'pageInProgress'] = await lastValueFrom(
            this.http.get<Page<Order>>(
                `${DeviceService.getEnvironmentParameter('api')}/api/orders`,
                { params }
            )
        );
        setTimeout(() => {
            this[state ? 'pageFulfilledLoading' : 'pageInProgressLoading'] =
                false;
        }, 50);
    }

    async doRefresh(event) {
        await Promise.all([this.loadOrders()]);
        event.target.complete();
    }
}

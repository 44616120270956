<ion-item-group *ngFor="let optionGroup of item.productOptionGroups">
    <ion-item-divider>
        <ion-label>{{ optionGroup.title }}</ion-label>
    </ion-item-divider>

    <ng-container *ngIf="optionGroup.type === 'multiple'">
        <ion-item *ngFor="let productOption of optionGroup.productOptions">
            <ion-checkbox
                mode="md"
                slot="start"
                *ngIf="optionGroup.type === 'multiple'"
                [(ngModel)]="productOption.selected"></ion-checkbox>
            <ion-label>
                <h2>{{ productOption.title }}</h2>
            </ion-label>
            <ion-note slot="end">{{
                productOption.price | currency: 'EUR'
            }}</ion-note>
        </ion-item>
    </ng-container>

    <ion-radio-group
        *ngIf="optionGroup.type === 'select'"
        [allowEmptySelection]="!optionGroup.required"
        [value]="defaultSelect(optionGroup)"
        (ionChange)="select($event, optionGroup)">
        <ng-container>
            <ion-item *ngFor="let productOption of optionGroup.productOptions">
                <ion-radio
                    mode="md"
                    slot="start"
                    [value]="productOption.id"></ion-radio>
                <ion-label>
                    <h2>{{ productOption.title }}</h2>
                </ion-label>
                <ion-note slot="end">{{
                    productOption.price | currency: 'EUR'
                }}</ion-note>
            </ion-item>
        </ng-container>
    </ion-radio-group>
</ion-item-group>
<div *ngFor="let selectedProductOption of selectedProductOptions">
    <app-product-option-group
        [item]="selectedProductOption"></app-product-option-group>
</div>

import { Component } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiService } from '@paystory/services/api.service';
import { HelperService } from '@paystory/services/helper.service';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.page.html',
    styleUrls: ['./profile-edit.page.scss'],
})
export class ProfileEditPage {
    errors: { [key: string]: string[] };

    registerForm = new UntypedFormGroup({
        address: new UntypedFormGroup({
            name: new UntypedFormControl(
                this.api.currentUserValue?.address?.name,
                Validators.required
            ),
        }),
        email: new UntypedFormControl(
            this.api.currentUserValue?.email,
            Validators.email
        ),
        phoneNumber: new UntypedFormControl(
            {
                value: this.api.currentUserValue?.mobileNumber,
                disabled: !!this.api.currentUserValue?.mobileNumber,
            },
            Validators.required
        ),
    });

    get email() {
        return this.registerForm.get('email');
    }
    get phoneNumber() {
        return this.registerForm.get('phoneNumber');
    }

    constructor(
        private helper: HelperService,
        private loadingCtrl: LoadingController,
        public api: ApiService,
        private toastCtrl: ToastController
    ) {}

    async save() {
        this.helper.validateAllFormFields(this.registerForm);

        if (!this.registerForm.valid) {
            const toast = await this.toastCtrl.create({
                message: 'Bitte fülle alle Felder korrekt aus.',
                duration: 3000,
                translucent: true,
                buttons: [{ text: 'Ok' }],
            });
            toast.present();
            return;
        }

        const message = 'Speichere ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        delete this.errors;
        await loading.present();
        try {
            await this.api.updateMyself({
                ...this.registerForm.value,
            });
            await loading.dismiss();
        } catch (e) {
            await loading.dismiss();
        }
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { UpDownComponent } from './up-down.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, DistancePipeModule],
    declarations: [UpDownComponent],
    exports: [UpDownComponent],
})
export class UpDownModule {}

import { CartService } from './../../services/cart.service';
import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from '@paystory/models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HelperService } from '@paystory/services/helper.service';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit {
    @Input() orderItem: OrderItem;
    quantityChange = new Subject();

    constructor(
        private cartService: CartService,
        private helperService: HelperService
    ) {}

    ngOnInit() {
        console.log('ProductItemComponent', this.orderItem);
        this.quantityChange
            .pipe(debounceTime(200))
            .subscribe(
                async (data: { product: OrderItem; quantity?: number }) => {
                    if (data.quantity === 0) {
                        await this.cartService.deleteProduct(data.product);
                    } else {
                        await this.cartService.changeProductAmount(
                            data.product,
                            data.quantity
                        );
                    }
                }
            );
    }

    async deleteProduct() {
        try {
            await this.helperService.confirmDelete();
            await this.cartService.deleteProduct(this.orderItem);
        } catch (error) {}
    }

    async amountChange(product: OrderItem, quantity: number) {
        this.quantityChange.next({
            product,
            quantity,
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DistancePipeModule } from '@paystory/pipes/distance/distance.pipe.module';
import { ParallaxHeaderDirective } from './parallax-header.directive';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, DistancePipeModule],
    declarations: [ParallaxHeaderDirective],
    exports: [ParallaxHeaderDirective],
})
export class ParallaxHeaderModule {}

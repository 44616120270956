import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-payment',
    templateUrl: './order-payment.page.html',
    styleUrls: ['./order-payment.page.scss'],
})
export class OrderPaymentPage implements OnInit {
    constructor(private navCtrl: NavController) {}

    ngOnInit() {}

    pay() {
        this.navCtrl.navigateRoot(['order-placed']);
    }
}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Bestellung</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <app-place-header [place]="order?.place"></app-place-header>
    <ng-container *ngIf="order">
        <div class="p-5 pt-3">
            <div class="list-header">Bestellinformationen</div>

            <ion-item
                class="item-border"
                lines="none">
                <div class="flex items-center py-3">
                    <div class="font-bold mr-5">
                        <div *ngIf="order?.number">Bestell Nr.:</div>
                        <div *ngIf="order?.checkoutCompletedAt">Datum:</div>
                        <div *ngIf="order?.details?.tableNumber">
                            Tisch Nr.:
                        </div>
                        <div *ngIf="order.paymentMethod">Zahlung:</div>
                        <div
                            *ngIf="order.state && (order.state !== 'paid' || order.paymentMethod !== 'cash')">
                            Status:
                        </div>
                    </div>
                    <div>
                        <div>{{ order?.number }}</div>
                        <div>
                            {{ order?.checkoutCompletedAt | date: 'dd.MM. HH:mm'
                            }} Uhr
                        </div>
                        <div>{{ order?.details?.tableNumber }}</div>
                        <div *ngIf="getPayMethod(order.paymentMethod)">
                            {{ getPayMethod(order.paymentMethod) | translate }}
                        </div>
                        <div>
                            <div
                                *ngIf="order.state !== 'paid' || order.paymentMethod !== 'cash'"
                                class="badge"
                                [class.badge-warning]="order.state === 'new'"
                                [class.badge-error]="order.state === 'cancelled'"
                                [class.badge-primary]="order.state === 'paid'"
                                [class.badge-success]="order.state === 'accepted'">
                                {{ 'orderState.' + order.state | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ion-item>

            <div class="list-header">Anmerkung</div>

            <ion-item
                class="item-border"
                lines="none">
                <ion-label>{{ order?.notes || 'Keine Anmerkung' }}</ion-label>
            </ion-item>

            <div class="list-header">Artikel</div>

            <div class="item_list">
                <ion-item
                    class="item-border mb-2"
                    lines="none"
                    *ngFor="let orderItem of order?.orderItems">
                    <div
                        [style.backgroundImage]="
            'url(' + orderItem?.product?.picture_600x600 + ')'
          "
                        class="shadow-xl rounded product-image"></div>
                    <ion-label>
                        <h2>{{ orderItem.quantity }} x {{ orderItem.name }}</h2>
                    </ion-label>
                    <div class="badge">
                        {{ orderItem?.total | currency: 'EUR' }}
                    </div>
                    <!--<div
      class="text_box customise"
      *ngIf="orderItem.orderItemOptionGroups?.length"
    >
      <ng-container
        [ngTemplateOutlet]="recursiveProducts"
        [ngTemplateOutletContext]="{ productOrOption: orderItem }"
      >
      </ng-container>
    </div>-->
                </ion-item>
            </div>

            <ng-template
                #recursiveProducts
                let-productOrOption="productOrOption">
                <div
                    *ngFor="let productOptionGroup of productOrOption.orderItemOptionGroups">
                    <div
                        *ngFor="let productOption of productOptionGroup.orderItemOptions">
                        <ion-item lines="none">
                            <ion-label> {{ productOption.title }} </ion-label>
                            <div class="badge">
                                {{ productOption?.total | currency: 'EUR' }}
                            </div>
                        </ion-item>
                        <ng-container
                            [ngTemplateOutlet]="recursiveProducts"
                            [ngTemplateOutletContext]="{ productOrOption: productOption }"></ng-container>
                    </div>
                </div>
            </ng-template>

            <div class="list-header">Zahlungsinformationen</div>

            <ion-item
                lines="none"
                class="bg-card rounded overflow-hidden item-border mb-2"
                *ngIf="order?.tip > 0">
                <ion-label> Trinkgeld </ion-label>
                <div class="badge">{{ order?.tip | currency: 'EUR' }}</div>
            </ion-item>
            <ion-item
                class="item-border"
                lines="none">
                <ion-label>Summe</ion-label>
                <div class="badge">{{ order?.total | currency: 'EUR' }}</div>
            </ion-item>

            <div class="list-header">Rechnung</div>

            <ion-item
                class="item-border"
                lines="none"
                (click)="downloadInvoice()">
                <ion-icon
                    name="document-attach-outline"
                    slot="start"></ion-icon>
                <ion-label>{{ order?.number }}.pdf</ion-label>
                <button
                    slot="end"
                    class="btn btn-xs normal-case">
                    Download
                </button>
            </ion-item>

            <!--<div class="list-header">Bestellstatus</div>

            <div>{{ 'orderState.' + order.state | translate }}</div>-->

            <!--<div>
                <ul class="steps steps-vertical">
                    <li
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'new' || (order.state === 'paid' && order.paymentMethod === 'cash')">
                        {{ 'orderState.new' | translate }}
                    </li>
                    <li
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'accepted'">
                        {{ 'orderState.accepted' | translate }}
                    </li>
                    <li
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'cancelled'">
                        {{ 'orderState.cancelled' | translate }}
                    </li>
                    <li
                        *ngIf="order.state !== 'paid' || order.paymentMethod !== 'cash'"
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'paid'">
                        {{ 'orderState.paid' | translate }}
                    </li>
                    <li
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'fulfilled'">
                        {{ 'orderState.fulfilled' | translate }}
                    </li>
                    <li
                        data-content="●"
                        class="step"
                        [class.step-neutral]="order.state === 'refunded'">
                        {{ 'orderState.refunded' | translate }}
                    </li>
                </ul>
            </div>-->
        </div>
    </ng-container>
</ion-content>

<!--
<ion-footer class="ion-no-border d-flex">
  <ion-toolbar>
    <ion-title>{{ order?.orderItems?.length }} Artikel</ion-title>
  </ion-toolbar>
</ion-footer>
-->

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlaceImprintPageRoutingModule } from './place-imprint-routing.module';

import { PlaceImprintPage } from './place-imprint.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PlaceImprintPageRoutingModule,
    ],
    declarations: [PlaceImprintPage],
})
export class PlaceImprintPageModule {}

/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/dot-notation */
import { LoadingController, NavController } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '@paystory/services/api.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.page.html',
    styleUrls: ['./verification.page.scss'],
})
export class VerificationPage implements OnInit, OnDestroy {
    confirmationCode: number;
    errors: { [key: string]: string[] };

    pin;

    timer = 60;
    interval;

    resendLoading: boolean = false;
    register: boolean = false;
    constructor(
        private navCtrl: NavController,
        private loadingCtrl: LoadingController,
        private api: ApiService
    ) {}

    ngOnInit() {
        this.startTimer();
    }

    startTimer() {
        this.timer = 60;
        this.interval = setInterval(() => {
            if (this.timer == 0) {
                clearInterval(this.interval);
                return;
            }
            --this.timer;
        }, 1000);
    }
    ngOnDestroy() {
        clearInterval(this.interval);
    }

    onCodeChanged(ev) {
        this.pin = ev;
    }

    onCodeCompleted(ev) {
        this.goToRegister();
    }

    async resend() {
        this.resendLoading = true;
        const message = 'SMS wird erneut gesendet ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        delete this.errors;
        await loading.present();
        try {
            await this.api.registerWithMobileNumber(
                {
                    mobileNumber: this.api.currentUserValue.mobileNumber,
                },
                true
            );
            this.startTimer();

            await loading.dismiss();
        } catch (e) {
            await loading.dismiss();
        } finally {
            this.resendLoading = false;
        }
    }

    async goToRegister() {
        if (this.register) return;
        this.register = true;
        const message = 'Login mit deiner Telefonnummer ...';
        const loading = await this.loadingCtrl.create({
            message,
            translucent: true,
        });
        delete this.errors;
        await loading.present();
        try {
            const result = await this.api.registerWithMobileNumber(
                {
                    mobileNumber: this.api.currentUserValue.mobileNumber,
                },
                false,
                this.pin
            );
            await loading.dismiss();
            if (result.hasOwnProperty('confirmed') && !result.confirmed) {
                this.errors = { confirmationCode: ['Ungültiger Code!'] };
            } else {
                await this.navCtrl.navigateRoot('/register');
            }
        } catch (e) {
            await loading.dismiss();
        }
    }
}

<div class="stepper">
    <button (click)="step('down')">
        <ion-icon name="remove-outline"></ion-icon>
    </button>
    <input
        class="stepper-input focus:outline-none hover:outline-none"
        min="0"
        type="tel"
        pattern="[0-9]+"
        [(ngModel)]="value"
        (blur)="modelChange($event)" />
    <button (click)="step('up')">
        <ion-icon name="add-outline"></ion-icon>
    </button>
</div>

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-title>Bestellübersicht</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ul class="w-full steps my-10">
        <li class="step step-primary">Erstellt</li>
        <li
            *ngIf="order?.paymentMethod !== 'cash'"
            class="step"
            [class.step-primary]="isPaid | async">
            Bezahlt
        </li>
        <li
            class="step"
            [class.step-primary]="isPrinted | async">
            Gedruckt
        </li>
    </ul>

    <div class="px-6 overflow-hidden">
        <swiper
            [slidesPerView]="1"
            class="pointer-events-none"
            [noSwiping]="true"
            (activeIndexChange)="indexChange($event)">
            <ng-template swiperSlide>
                <div
                    class="font-bold text-2xl text-secondary uppercase tracking-wider mb-5">
                    Bestellung erstellt
                </div>
                <div class="text-sm font-medium">
                    Wir prüfen noch kurz den Zahlungseingang.<br />
                    Bitte warte einen Moment.
                </div>
                <ng-lottie
                    *ngIf="swiper?.swiperRef?.activeIndex == 0"
                    [options]="loadingAnimation"></ng-lottie>
            </ng-template>
            <ng-template
                swiperSlide
                *ngIf="order?.paymentMethod !== 'cash'">
                <div
                    class="font-bold text-2xl text-secondary uppercase tracking-wider mb-5">
                    Bestellung bezahlt
                </div>
                <div class="text-sm font-medium">
                    Vielen Dank.<br />
                    Deine Zahlung kam an und deine Bestellung wird nun dem
                    Restaurant übermittelt.
                </div>
                <ng-lottie
                    *ngIf="swiper?.swiperRef?.activeIndex == 1"
                    [options]="paidAnimation"></ng-lottie>
            </ng-template>
            <ng-template swiperSlide>
                <div
                    class="font-bold text-2xl text-secondary uppercase tracking-wider mb-5">
                    Bestellung übermittelt
                </div>
                <div class="text-sm font-medium mt-5">
                    <strong>Alles erledigt!.</strong> Deine Bestellung wurde dem
                    Restaurant übermittelt. Du kannst dich jetzt zurücklehnen
                    und auf deine Bestellung warten. Sie sollte zeitnah bei dir
                    eintreffen.
                </div>
                <ng-lottie
                    *ngIf="swiper?.swiperRef?.activeIndex == 2"
                    [options]="printAnimation"></ng-lottie>
            </ng-template>
        </swiper>
    </div>
</ion-content>
<ion-footer>
    <ion-toolbar color="primary">
        <div class="grid grid-cols-2 gap-4">
            <ion-button
                size="medium"
                shape="full"
                (click)="goToOrders()">
                Bestellübersicht
            </ion-button>
            <ion-button
                *ngIf="placeId"
                size="medium"
                shape="full"
                (click)="goToPlace()">
                Erneut bestellen
            </ion-button>
        </div>
    </ion-toolbar>
</ion-footer>

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DeviceService } from '@paystory/services/device.service';
import { LocationService } from '@paystory/services/location.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { DeliveryAddress } from '../saved-addresses/saved-addresses.page';
import { ApiService } from '@paystory/services/api.service';

@Component({
    selector: 'app-confirm-address',
    templateUrl: './confirm-address.page.html',
    styleUrls: ['./confirm-address.page.scss'],
})
export class ConfirmAddressPage implements OnInit {
    @Input() forceDefault = false;
    @Input() address: DeliveryAddress = {};
    addressForm: UntypedFormGroup;

    constructor(
        private route: ActivatedRoute,
        private modalCtrl: ModalController,
        private http: HttpClient,
        private locationService: LocationService,
        private readonly api: ApiService
    ) {}

    ngOnInit() {
        console.log('this.address', this.address);
        if (this.forceDefault || !this.api.currentUserValue?.address) {
            this.address.default = true;
        }
        if ((this.address as any)?.streetNumber?.length) {
            this.address.address += ` ${
                (this.address as any).streetNumber || ''
            }`;
        }
    }

    async create() {
        let address = this.address;
        const addressString = `
      ${address.address || ''}, ${address.zipCode || ''} ${address.city || ''}
    `;
        const data = await this.locationService.getLatLngFromAddress(
            addressString
        );
        console.log('latLng', data);

        address = {
            ...address,
            latitude: data.geometry.location.lat(),
            longitude: data.geometry.location.lng(),
        };
        console.log('this.addressForm', address);

        await (!address.default
            ? lastValueFrom(
                  this.http.post(
                      `${DeviceService.getEnvironmentParameter(
                          'api'
                      )}/api/users/me/delivery_addresses`,
                      address
                  )
              )
            : this.api.updateMyself({ address }));

        this.modalCtrl.dismiss({
            saved: true,
        });
    }

    close() {
        this.modalCtrl.dismiss();
    }
}

<ion-header>
    <ion-toolbar color="paystory-dark">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title class="title-logo"
            ><img
                class="center"
                src="/assets/logo-text-on-dark.svg"
        /></ion-title>
        <ion-buttons slot="end">
            <ion-button [routerLink]="['/saved-addresses']">
                <ion-icon name="location-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="toggleSearch()">
                <ion-icon name="search"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <button
        *ngIf="formattedAddress"
        class="btn normal-case flex items-center justify-start rounded-none w-full"
        [routerLink]="['/saved-addresses']">
        <div class="-mt-1 gap-4 flex items-center justify-start">
            <ion-icon
                class="text-2xl"
                name="location-outline"></ion-icon>
            <div class="flex flex-col items-start font-normal mt-1">
                <div>
                    {{formattedAddress.address}}
                    {{formattedAddress.streetNumber}}
                </div>
                <div>
                    {{formattedAddress.zipCode}} {{formattedAddress.city}}
                </div>
            </div>
        </div>
    </button>
</ion-header>

<ion-content>
    <ion-refresher
        slot="fixed"
        (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="p-3">
        <ion-searchbar
            [(ngModel)]="listSearch"
            (ionChange)="loadPlaces()"
            placeholder="Suchen"
            [hidden]="!search"
            showCancelButton="never"
            showClearButton="never"></ion-searchbar>

        <!--<div *ngIf="!(apiService.currentUser | async)?.address?.latitude">
      <ion-button [routerLink]="['/set-location']" class="set-location" size="medium" expand="block"
        color="paystory-dark">Standort hinterlegen</ion-button>
    </div>-->

        <ion-segment
            mode="ios"
            (ionChange)="deliveryChanged($event)"
            [ngModel]="allowedType">
            <ion-segment-button value="order">
                <ion-label>InHouse</ion-label>
            </ion-segment-button>
            <ion-segment-button value="delivery">
                <ion-label>Lieferung</ion-label>
            </ion-segment-button>
            <ion-segment-button value="pickup">
                <ion-label>Abholung</ion-label>
            </ion-segment-button>
        </ion-segment>
        <div
            class="pt-5 pb-0 font-semibold text-right text-xs  uppercase tracking-wider leading-none">
            {{ placeService.places?.totalItemCount || 0 }} Standort{{
            placeService.places?.totalItemCount === 1 ? '' : 'e' }}
        </div>

        <place-item
            *ngFor="let place of placeService.places?.items; trackBy: trackById"
            [place]="place"></place-item>

        <loading-item
            *ngIf="!(placeService.places?.items?.length > 0)"
            [loading]="loading"
            [message]="'Keine Standorte gefunden'"></loading-item>

        <ion-infinite-scroll
            *ngIf="!loadedAll"
            (ionInfinite)="doInfinite($event)">
            <ion-infinite-scroll-content> </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
    <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        (click)="scanQrCode()">
        <ion-fab-button>
            <ion-icon name="qr-code-outline"></ion-icon>
        </ion-fab-button>
    </ion-fab>
</ion-content>
